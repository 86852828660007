/* eslint-disable no-unused-vars */
import React from "react"
import { TextField, Grid, Typography, Button } from "@mui/material"
import { BiblioForm } from "components"
// so usado 1 vez
const BlackList = ({ id, handleSetBlackList }) => {
  return (
    <div>
      <Typography>Blacklist</Typography>
      <BiblioForm handleCommit={handleSetBlackList} id={id} />
    </div>
  )
}
export default BlackList
