import React from "react"
import { Typography } from "@mui/material"
import { COLORS, IMAGES } from "_constants"
import Spinner from "../Spinner"

const backgroundColors = COLORS

const SplashScreen = ({ language, library }) => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor:
          backgroundColors[Math.ceil(Math.random() * backgroundColors.length - 1)],
        height: "550px",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          padding: "1rem",
          height: "100%",
          width: "calc(100% - 2rem)",
          top: 0,
          left: 0,
          position: "absolute",
          zIndex: 1,
        }}
      >
        <Typography
          sx={{ fontWeight: 900, lineHeight: 1 }}
          variant="h6"
          color={"#FFF"}
        >
          {library.label}
        </Typography>
        <Spinner language={language} />
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          position: "absolute",
          filter: "grayscale(1)",
          backgroundImage: `url(${
            IMAGES[Math.ceil(Math.random() * IMAGES.length - 1)]
          })`,
          backgroundSize: "cover",
          opacity: 0.5,
        }}
      ></div>
    </div>
  )
}

export default SplashScreen
