/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { apiService } from "config"

import ButtonFlat from "components/ButtonFlat"

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps"

import { ADMIN, LOCAL_ADMIN } from "_constants"

import { useStoredSession } from "hooks"

const GEO_URL = `${process.env.REACT_APP_API_URL}/v1/world-map`

const handleWorldMapRequest = async () => {
  const url = GEO_URL
  return await apiService.get(`${url}`)
}

const DEFAULT_POSITION = {
  coordinates: [-4.469940010247374, 39.7931056228892],
  zoom: 5,
}
const MX_POSITION = {
  zoom: 1.6581169343123032,
  coordinates: [-100.39759428104078, 24.19882427327882],
}

const PY_POSITION = {
  zoom: 4.523689788263429,
  coordinates: [-58.476516093684936, -23.22305328164793],
}

const Map = () => {
  const [markers, setMarkers] = useState([])
  const [position, setPosition] = useState(DEFAULT_POSITION)
  // const [map, setMap] = useState({})

  // User Session
  const [user] = useStoredSession()

  const handleMoveEnd = ({ zoom, coordinates }) => {
    setPosition({ zoom, coordinates })
    console.log(position)
  }

  // useEffect(async () => {
  //   const map = await handleWorldMapRequest()

  //   setMap(map)
  // }, [])

  useEffect(async () => {
    const url =
      user.type === ADMIN
        ? "https://api-elastic7.orex.es/v1/statistics"
        : `https://api-elastic7.orex.es/v1/statistics?prefix=${user.prefix}`
    const statisticsEndpoint = await fetch(url)

    const statistics = await statisticsEndpoint.json()

    setMarkers(statistics)
  }, [])

  return (
    <>
      <div>
        {/* {position.zoom} */}
        <ButtonFlat onClick={() => setPosition(DEFAULT_POSITION)}>Espana</ButtonFlat>
        <ButtonFlat onClick={() => setPosition(MX_POSITION)}>Mexico</ButtonFlat>
        <ButtonFlat onClick={() => setPosition(PY_POSITION)}>Paraguay</ButtonFlat>
      </div>
      <ComposableMap
        //   projection="geoAzimuthalEqualArea"
        projectionConfig={{
          //     rotate: [58, 20, 0],
          scale: 500,
        }}
        height={400}
        width={800}
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
        >
          {/* {({ scale }) => { */}
          {/* return ( */}
          {/* <> */}
          <Geographies geography={GEO_URL}>
            {({ geographies }) =>
              geographies
                // .filter((d) => d.properties.REGION_UN === "Europe")
                .map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#EAEAEC"
                    stroke="#D6D6DA"
                    strokeWidth={
                      position.zoom / 10 < 0.25 ? position.zoom / 10 : 0.25
                    }
                  />
                ))
            }
          </Geographies>
          {markers.map(({ name, coordinates, recomendations, markerOffset }) => (
            <Marker key={name} coordinates={coordinates}>
              {/* <text
                textAnchor="middle"
                y={markerOffset}
                style={{
                  transform: `scale(${
                    position.zoom / 10 < 0.095 ? position.zoom / 10 : 0.095
                  })translate(0px, -25px)`,
                  fontFamily: "system-ui",
                  fill: "#5D5A6D",
                }}
              >
                {recomendations}
              </text> */}
              <g
                fill="none"
                stroke="#FF5533"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-12, -24)"
                style={{
                  transform: `scale(${
                    position.zoom / 10 < 0.085 ? position.zoom / 10 : 0.085
                  })`,
                }}
              >
                <circle cx="12" cy="10" r="3" />
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
              </g>
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{
                  transform: `scale(${
                    position.zoom / 10 < 0.085 ? position.zoom / 10 : 0.085
                  })`,
                  fontFamily: "system-ui",
                  fill: "#5D5A6D",
                }}
              >
                {name}
              </text>
            </Marker>
          ))}
          {/* </> */}
          {/* ) */}
          {/* }} */}
        </ZoomableGroup>
      </ComposableMap>
    </>
  )
}

export default Map
