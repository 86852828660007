/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import { CompareArrows } from "@mui/icons-material"
import chroma from "chroma-js"

import { useStoredSession } from "hooks"
import { ADMIN, LOCAL_ADMIN } from "_constants"

import { apiService } from "config"
import { clean } from "utils"

const heatMapCalc = (max, min) => {
  return max - (max - min) <= min ? max : max - (max - min)
}

var col1 = "#fff7ec",
  col5 = "#00a487",
  col2 = "#cf0000"

const MAX_RESULTS = 24

const HeatMap = () => {
  const [table, setTable] = useState([])
  const [{ min, max }, setMinMax] = useState({ min: 0, max: 0 })
  const [loading, setLoading] = useState(false)
  const [user] = useStoredSession()

  const url =
    user.type === ADMIN
      ? "https://api-elastic7.orex.es/v1/statistics-biblio"
      : `https://api-elastic7.orex.es/v1/statistics-biblio?prefix=${user.prefix}`

  const getCurrentBooks = async (biblio) => {
    setLoading(true)
    const baseUrl = `/biblio`
    const {
      data: { book, query_bibliografico },
    } = await apiService.get(`${baseUrl}?biblionumber=${biblio}`)

    setLoading(false)

    return book
  }

  useEffect(async () => {
    const statisticsEndpoint = await fetch(url)

    const statistics = await statisticsEndpoint.json()

    var x = []
    for (let key in statistics) {
      if (!key.includes(","))
        x.push({
          uniqueBIblio: key,
          requests: statistics[key].length,
          max: Math.max(...statistics[key]),
          min: Math.min(...statistics[key]),
        })
    }

    // var numberOfRequests = x.sort((a, b) => b.requests - a.requests)
    var maxRecomendations = x
      .sort((a, b) => b.max - a.max)
      .filter((item, key) => key < MAX_RESULTS)

    var max = maxRecomendations[0] ? maxRecomendations[0].max : 0
    var min = maxRecomendations[19] ? maxRecomendations[19].max : 0

    var bookString = maxRecomendations.map((book) => book.uniqueBIblio).join(",")

    var booksResponse = await getCurrentBooks(bookString)

    var books = maxRecomendations.map((recomendation, index) => {
      return {
        ...recomendation,
        book: booksResponse.find((book) => book._id === recomendation.uniqueBIblio),
      }
    })
    setMinMax({ max, min })
    setTable(books)
  }, [])

  const heatmap = chroma.scale([col1, col2]).domain([min, max]).classes(MAX_RESULTS)
  return (
    <>
      {loading ? (
        <div style={{ padding: "1rem 8rem" }}>Loading...</div>
      ) : (
        <div
          style={{
            maxWidth: "764px",
            padding: "1rem 8rem",
            display: "flex",
            flexWrap: "wrap",
            margin: "0 auto",
          }}
        >
          {table.map((row, index) => (
            <p
              style={{
                height: 175,
                width: 175,
                margin: 0,
                padding: ".5rem",
                backgroundColor: heatmap(heatMapCalc(row.max, row.min)).hex(),
                color:
                  chroma.contrast(
                    "#000",
                    heatmap(heatMapCalc(row.max, row.min)).hex()
                  ) < 4.5
                    ? "White"
                    : "black",
              }}
            >
              <a
                style={
                  chroma.contrast(
                    "#000",
                    heatmap(heatMapCalc(row.max, row.min)).hex()
                  ) < 4.5
                    ? { color: "White" }
                    : { color: "black" }
                }
                href={`//${
                  row.book ? row.book._source.biblioteca : ""
                }.orex.es/cgi-bin/koha/opac-detail.pl?biblionumber=${
                  row.book ? row.book._source.biblionumber : ""
                }`}
              >
                <Typography size="large">
                  {row.book ? clean(row.book._source.title) : ""}
                </Typography>
              </a>
              <div style={{ display: "flex" }}>
                <Typography size="large">{row.max}</Typography>{" "}
                <CompareArrows size="large" />
                <Typography size="large">{row.min}</Typography>
              </div>
              <Typography size={12}>Max, Min</Typography>
              Temas: {row.book ? row.book._source.materia1.join(",") : ""}
            </p>
          ))}
        </div>
      )}
    </>
  )
}

export default HeatMap
