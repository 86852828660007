import { useEffect } from "react"
import { useRecoilState } from "recoil"

import { libraryListAtom } from "../atoms"
import { apiService } from "../config"

const useGetAllLibraries = () => {
  const [libraries, setLibraries] = useRecoilState(libraryListAtom)
  const setNewLibraries = (newLibrary) => {
    return setLibraries(newLibrary)
  }
  useEffect(async () => {
    if (!libraries.length) {
      const baseUrl = `/library`
      const results = await apiService.get(`${baseUrl}`)
      return setLibraries(results.data)
    }
    return libraries
  }, [])
  return [libraries, setNewLibraries]
}

export default useGetAllLibraries
