import React from "react"
import { Typography, NativeSelect, FormControl, Grid } from "@mui/material"

const LibraryDropDown = ({
  user,
  selectValue,
  setSelectValue,
  libraries,
  showDefault,
}) => (
  <FormControl sx={{ pb: 2 }} fullWidth>
    <Grid
      justifyContent="space-between"
      alignItems="flex-start"
      container
      style={{ width: "100%" }}
    >
      <Grid item sx={{ pr: 2 }}>
        <Typography>Select Library</Typography>
      </Grid>
      <Grid item>
        <NativeSelect
          disabled={user.type !== "ADMIN"}
          required
          id="libraryId"
          label="Libraries"
          value={selectValue.libraryId}
          onChange={({ target }) =>
            setSelectValue({
              ...selectValue,
              libraryId: target.value,
            })
          }
        >
          {showDefault && <option value={"0"}>{"Todas"}</option>}
          {libraries &&
            libraries.map((library) => (
              <option key={`${library.id}-${library.prefix}`} value={library.id}>
                {library.name}
              </option>
            ))}
        </NativeSelect>
      </Grid>
    </Grid>
  </FormControl>
)

export default LibraryDropDown
