/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"

import Filter from "components/Filter"
import { setLanguage } from "i18n"

// import { FILTER_CONFIGS } from "./BookFilters.Constants"

const BookFilters = ({
  label,
  filters: { cities, countries, subject },
  bookList,
  setBooks,
  value,
  setValue,
  language,
  resultsFrom,
}) => {
  const tranlations = setLanguage(language)
  const internal = resultsFrom

  const FILTER_CONFIGS = [
    {
      type: "dropdown",
      multiple: true,
      id: "subject",
      title: "Materia",
      handleOptions,
      value: value,
      filters: subject.map((s) => ({ id: s, value: s })),
    },
    {
      type: "dropdown",
      multiple: true,
      id: "country",
      title: "Pais",
      handleOptions,
      value: value,
      filters: countries.map((country) => ({
        id: country.id,
        value: country.label,
      })),
    },
    // {
    //   type: "dropdown",
    //   multiple: true,
    //   id: "city",
    //   title: "Ciudad",
    //   handleOptions,
    //   value: value,
    //   filters: cities.map((city) => ({ id: city.id, value: city.label })),
    // },
    {
      type: "checkbox",
      id: "internal",
      title: `Disponible en ${label}`,
      value: value,
      handleOptions,
    },
    {
      type: "checkbox",
      id: "curated",
      title: "Lectura recomendada por su biblioteca",
      value: value,
      handleOptions,
    },
  ]

  useEffect(() => {
    let filterStore = {}
    FILTER_CONFIGS.forEach(
      (filter) =>
        (filterStore = {
          ...filterStore,
          [filter.id]: filter.multiple ? [] : false,
        })
    )
    if (internal) {
      setBooks(bookList.filter((book) => book.internal))
      setValue({
        internal: true,
      })
    } else {
      setValue(filterStore)
    }
  }, [])

  function handleOptions(_value, id) {
    const newfilters = {
      ...value,
      [id]: _value,
    }
    setValue(newfilters)
    const filtered = bookList.filter((book) => {
      const allFilters = FILTER_CONFIGS.filter((filter) => {
        return filter.multiple
          ? newfilters[filter.id] && newfilters[filter.id].length
            ? newfilters[filter.id].some((v) =>
                book[filter.id] && book[filter.id].includes
                  ? book[filter.id] && book[filter.id].includes(v)
                  : book[filter.id] === v
              )
            : true
          : newfilters[filter.id]
          ? book[filter.id] === newfilters[filter.id]
          : true
      })

      return allFilters.length === FILTER_CONFIGS.length
    })
    setBooks(filtered)
  }
  // TODO: ADD PARAMS
  return FILTER_CONFIGS.map((config) => (
    <Filter resultsFrom={internal} key={config.id} {...config} />
  ))
}

export default BookFilters
