const breakpoints = {
  global: {
    breakpoints: {
      xsmall: {
        value: 375,
      },
      small: {
        value: 568,
      },
      medium: {
        value: 768,
      },
      large: {
        value: 1024,
      },
      xlarge: {
        value: 1366,
      },
    },
  },
}
export default breakpoints
