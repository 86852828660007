export const COLORS = [
  "#6fadd6",
  "#d59c9e",
  "#efd46c",
  "#64c289",
  "#dae0ed",
  "#e35636",
  "#818644",
  "#d74243",
  "#8d4141",
  "#806a5c",
  "#93c0b1",
  "#e4b46a",
]

export const GRAY = "#555555"
export const WHITE = "#FFFFFF"
export const DARK = "#333333"
