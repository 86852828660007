import { useRecoilState } from "recoil"
import { pageAtom } from "atoms"
// import { useStoredSession } from "../hooks"

const useDropDownMenu = (value) => {
  const [page, _setPage] = useRecoilState(pageAtom || value)
  // const [user] = useStoredSession()
  // Send user info to pages sort of protected routes
  const setPage = (_page) => {
    // if (user && user.username) {
    _setPage(_page)
    // } else {
    //   _setPage("login")
    // }
  }

  console.log("page", page)
  return [page, setPage]
}

export default useDropDownMenu
