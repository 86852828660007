import React from "react"

import SelectFilter from "./SelectFilter"
import CheckBoxFilter from "./CheckBoxFilter"

const Filter = (props) => {
  switch (props.type) {
    case "dropdown":
      return <SelectFilter {...props} />
    default:
      return <CheckBoxFilter {...props} />
  }
}

export default Filter
