/* eslint-disable no-unused-vars */
import React from "react"

import { Grid, Typography, Box } from "@mui/material"

import { GRAY, WHITE, DARK } from "_constants"
import { COLORS } from "_constants"

import { clean, getShortYear } from "utils"

const SliderImage = ({ title, pallete, book, googleBookInfo }) => {
  const wider = Math.random() * (256 - 128 + 1) + 128
  const inner = Math.random() * (128 - 78 + 1) + 78

  const hasImage =
    googleBookInfo &&
    googleBookInfo.volumeInfo.imageLinks &&
    googleBookInfo.volumeInfo.imageLinks.thumbnail

  return (
    <svg viewBox={`0 0 ${wider + 4} ${wider + 4}`}>
      <circle
        cx={(wider + 2) / 2}
        cy={(wider + 2) / 2}
        r={(wider + 2) / 2}
        stroke={pallete[1] || GRAY}
        strokeWidth="1"
        fill={WHITE}
      />

      <circle
        cx={wider / 2}
        cy={wider / 2}
        r={inner / 2}
        stroke="white"
        strokeWidth="3"
        fill={pallete[2] || DARK}
      />
      <circle
        cx={wider / 2}
        cy={wider / 2}
        r={wider / 4}
        stroke="white"
        strokeWidth="3"
        fill={pallete[3] || WHITE}
      />
      {hasImage ? (
        <>
          <defs>
            <clipPath id={`image-${book.id}`}>
              <circle
                cx={wider / 2}
                cy={wider / 2}
                r={inner / 4}
                fill={pallete[1] || WHITE}
              />
            </clipPath>
          </defs>
          <image
            width={wider}
            height={wider}
            xlinkHref={googleBookInfo.volumeInfo.imageLinks.thumbnail}
            clipPath={`url(#image-${book.id})`}
          />
        </>
      ) : (
        <g>
          <circle
            fill={COLORS[Math.ceil(Math.random() * COLORS.length)]}
            id="top"
            cx={wider / 2}
            cy={wider / 2}
            r={inner / 2}
          ></circle>
          <text
            textAnchor="middle"
            x="50%"
            y="55%"
            fill="#FFF"
            fontSize={"2rem"}
            fontWeight="bold"
          >
            {/* <Typography style={{ fontWeight: 900 }} variant={"h2"}> */}
            {title.substring(0, 1)}
            {/* </Typography> */}
          </text>
        </g>
      )}
    </svg>
  )
}

// CarouselItem
const SliderItem = ({ book }) => {
  const { pallete, google, title, author } = book
  const googleBookInfo = google && google.totalItems ? google.items[0] : null
  const hasDate = googleBookInfo && googleBookInfo.volumeInfo.publishedDate

  return (
    <div
      key={`${title}-${author}`}
      style={{
        height: "500px",
        maxWidth: "350px",
      }}
    >
      <Grid
        container
        sx={{ py: 2 }}
        justifyContent="space-between"
        alignContent={"center"}
        flexDirection={"column"}
      >
        <Box
          style={{
            minHeight: "100px",
            width: "100%",
          }}
          item
        >
          <SliderImage
            book={book}
            googleBookInfo={googleBookInfo}
            title={title}
            pallete={pallete}
          />
        </Box>
        <Typography style={{ fontWeight: 900 }} variant="h3">
          {hasDate && getShortYear(googleBookInfo.volumeInfo.publishedDate)}
        </Typography>
        <Typography variant="subtitle2">
          <span style={{ fontWeight: 300 }}>{author}</span>
        </Typography>
        <Typography
          sx={{ fontWeight: 900, lineHeight: 1, maxWidth: "175px" }}
          variant="h5"
        >
          {title && clean(title)}
        </Typography>
      </Grid>
    </div>
  )
}

export default SliderItem
