/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react"
import { Menu, Divider, MenuItem, IconButton, Typography, Grid } from "@mui/material"
import { MoreVert, Info, MenuBook, SupervisedUserCircle } from "@mui/icons-material/"

import { useDropDownMenu, useStoredSession } from "hooks/"
import { setLanguage } from "i18n"

import SessionButton from "../SessionButton"

const ITEM_HEIGHT = 200

const DropMenu = ({ placement, language }) => {
  const translations = setLanguage(language)
  const [user] = useStoredSession()
  const [anchorEl, setAnchorEl] = useState(null)
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)

  const [page, setPage] = useDropDownMenu("grid")

  useEffect(() => {
    const div = document.createElement("div")
    const newContainer = document
      .querySelector(placement)
      .querySelectorAll("#books-widget")[0]
      .shadowRoot.querySelector("#long-button")
    if (!anchorEl) {
      setAnchorEl(newContainer)
    }
  }, [anchorEl])

  const handleClick = (evt) => {
    setOpen(!open)
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getSelectedPage = (current) => ({
    fontWeight: page === current ? "bold" : "normal",
  })

  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
        {anchorEl ? (
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            container={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem onClick={() => setPage("about")} key={"about-item"}>
              <Grid justifyContent="flex-start" sx={{ width: "100%" }} container>
                <Grid sx={{ mr: 1 }} item>
                  <Info fontSize="small" />
                </Grid>
                <Grid item>
                  <Typography sx={getSelectedPage("about")} fontSize="small">
                    {" "}
                    {translations["about"]}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem onClick={() => setPage("grid")} key={"grid-item"}>
              <Grid justifyContent="flex-start" sx={{ width: "100%" }} container>
                <Grid sx={{ mr: 1 }} item>
                  <Info fontSize="small" />
                </Grid>
                <Grid item>
                  <Typography sx={getSelectedPage("grid")} fontSize="small">
                    {translations["grid_detail"]}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem
              onClick={() => setPage(user.username ? "recomendation" : "login")}
              key={"recomendations-item"}
            >
              {" "}
              <Grid justifyContent="flex-start" sx={{ width: "100%" }} container>
                <Grid sx={{ mr: 1 }} item>
                  <MenuBook fontSize="small" />
                </Grid>
                <Grid item>
                  <Typography sx={getSelectedPage("recomendation")} fontSize="small">
                    {" "}
                    {translations["add_recomendations"]}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            {user.username && user.admin && (
              <MenuItem onClick={() => setPage("admin")} key={"admin-item"}>
                <Grid justifyContent="flex-start" sx={{ width: "100%" }} container>
                  <Grid sx={{ mr: 1 }} item>
                    <SupervisedUserCircle fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Typography sx={getSelectedPage("admin")} fontSize="small">
                      {" "}
                      {translations["admin_page"]}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            )}
            <Divider />
            <SessionButton handleClose={handleClose} />
          </Menu>
        ) : (
          <div></div>
        )}
      </IconButton>
    </>
  )
}
export default DropMenu
