import { createTheme } from "@mui/material/styles"
import { red, lightBlue } from "@mui/material/colors"

const theme = createTheme({
  palette: {
    // mode: "dark",

    primary: {
      main: lightBlue[800],
    },
    secondary: {
      main: "#FFF",
    },
    // action: {
    //   active: lightBlue[200],
    //   activeOpacity: 1,
    //   hover: lightBlue[100],
    //   hoverOpacity: 0.7,
    //   focus: lightBlue[600],
    //   focusOpacity: 1,
    //   selected: lightBlue[300],
    //   selectedOpacity: 1,
    // },

    error: {
      main: red.A400,
    },
  },
  components: {
    MuiInput: {
      root: {
        flexWrap: "wrap",
      },
    },
    // MuiButtonBase: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#333",
    //       color: "#FFF",
    //       border: "none",
    //     },
    //   },
    // },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "black",
        },
      },
    },
  },
})

export default theme
