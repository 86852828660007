/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Grid } from "@mui/material"

import { useDropDownMenu } from "hooks"

import LoginForm from "features/LoginForm/LoginForm"
import { List } from "features/Recomendations"
import Admin from "features/Admin"

import AboutPage from "pages/AboutPage"
import DetailPage from "pages/DetailPage"

// Layout Master TODO
import { Footer } from "pages"
import GridPage from "pages/GridPage"

const BookStage = ({ book, user, bookList, filters, library, language }) => {
  const [value, setValue] = useState({})
  const [sidebar] = useState(false)

  const [books, setBooks] = useState(bookList)
  const [selected, setSelected] = useState(null)
  const [position, setPosition] = useState(1)
  const [page] = useDropDownMenu("grid")

  const goToPrevious = () => {
    if (position !== 0) {
      setSelected(books[position - 1])
      setPosition(position - 1)
    }
  }

  const goToNext = () => {
    if (position < books.length - 1) {
      setSelected(books[position + 1])
      setPosition(position + 1)
    }
  }

  const googleBookInfo =
    selected && selected.google.totalItems ? selected.google.items[0] : null
  const googleBookSearch =
    googleBookInfo && selected.google.searchInfo ? selected.google.items[0] : null
  const hasImages = googleBookInfo && googleBookInfo.volumeInfo.imageLinks

  const components = {
    admin: user.admin ? Admin : null,
    recomendation: user ? List : null,
    grid: selected ? DetailPage : GridPage,
    login: LoginForm,
    about: AboutPage,
  }
  const Component = components[page]

  return (
    <Grid
      sx={{ height: "100%", pt: 2 }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Component
        user={user}
        sidebar={sidebar}
        value={value}
        book={book}
        books={books}
        language={language}
        library={library}
        filters={filters}
        setValue={setValue}
        bookList={bookList}
        prefix={library.prefix}
        position={position}
        googleBookInfo={googleBookInfo}
        googleBookSearch={googleBookSearch}
        hasImages={hasImages}
        selected={selected}
        setBooks={setBooks}
        setPosition={setPosition}
        setSelected={setSelected}
        goToPrevious={goToPrevious}
        goToNext={goToNext}
      />
      <Footer />
    </Grid>
  )
}

export default BookStage
