/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { FormContainer, TextFieldElement } from "react-hook-form-mui"
import { Typography, Button, NativeSelect, FormControl, Alert } from "@mui/material"

import { apiService } from "config"
import { useGetAllUsers, useStoredSession } from "hooks"
import { ButtonFlat } from "components"
import LibraryDropDown from "./LibraryDropDown"

const AddUserForm = ({ libraries, handleSetMode, handlesetLocalUsers }) => {
  const [user] = useStoredSession()

  const defaultFormCreation = {
    libraryId: user.libraryId,
    type: "STANDARD",
    createdBy: user.username,
  }
  const [users, setNewUsers] = useGetAllUsers()
  const [selectValue, setSelectValue] = useState(defaultFormCreation)
  const [status, setStatus] = useState(false)

  const handleCreateUser = async (data) => {
    const baseUrl = `/user`
    const { libraryId, type, createdBy } = selectValue
    const formValue = { ...data, libraryId, type, createdBy }

    const results = await apiService.post(`${baseUrl}`, formValue).catch((error) => {
      setStatus("error")
    })
    if (results) {
      setStatus("success")
      handleSetMode("table")
      handlesetLocalUsers([...users, results.data])
      return setNewUsers([...users, results.data])
    }
  }

  return (
    <FormContainer
      defaultValues={{
        libraryId: 1,
        username: "",
        email: "",
      }}
      onSuccess={(data) => {
        handleCreateUser(data)
      }}
    >
      <LibraryDropDown
        user={user}
        selectValue={selectValue}
        setSelectValue={(value) => setSelectValue(value)}
        libraries={libraries}
      />
      <FormControl sx={{ pb: 2 }} fullWidth>
        <Typography>Type</Typography>
        <NativeSelect
          disabled={user.type !== "ADMIN"}
          required
          id="libraryId"
          label="Libraries"
          onChange={({ target }) =>
            setSelectValue({
              ...selectValue,
              type: target.value,
            })
          }
        >
          <option key={`standard`} value={`STANDARD`}>
            {`Standard User`}
          </option>
          <option key={`local-admin`} value={`LOCAL_ADMIN`}>
            {`Library Administrator`}
          </option>
        </NativeSelect>
      </FormControl>
      <TextFieldElement
        id="username"
        name="username"
        required
        sx={{ width: "100%", pb: 2 }}
        label={"Username"}
        variant="standard"
        type={"text"}
      />
      <TextFieldElement
        id="email"
        name="email"
        required
        sx={{ width: "100%", pb: 2 }}
        label={"E-Mail"}
        variant="standard"
        type={"email"}
      />
      <ButtonFlat disableElevation type="submit" size="small">
        Add User
      </ButtonFlat>
      {status && (
        <Alert severity={status} sx={{ width: "100%" }}>
          {`Can't create user!`}
        </Alert>
      )}
    </FormContainer>
  )
}
export default AddUserForm
