/* eslint-disable no-unused-vars */
import React, { useState, useEffect, lazy, Suspense } from "react"
import { useRecoilState } from "recoil"

import { Container, Typography } from "@mui/material"

import { apiService } from "./config"

import { locationAtom, hostnameAtom } from "./atoms"
import { useStoredSession } from "./hooks"

import { setLanguage } from "./i18n"
import { generateUUID } from "./utils"

import BookSlider from "./features/BookSlider"
import Footer from "./components/Footer"
import { SplashScreen } from "./components"

const GUID = generateUUID()

const BookStage = lazy(() => import("./pages/BookStage"))

const getLocations = async () => {
  const url = `/locations`
  return await apiService.get(`${url}`)
}

const getBookList = async (isbns, biblios, library) => {
  const url = `/recomendations`
  const bibliosSlug = !biblios.includes("_null") ? `&biblios=${biblios}` : ""

  return await apiService.get(
    `${url}?isbns=${isbns}${bibliosSlug}&libraryId=${library.id}&prefix=${library.prefix}&blacklist=${library.useBlacklist}&guid=${GUID}`
  )
}

const getCurrentBook = async (biblio) => {
  const baseUrl = `/biblio`
  const {
    data: { book, query_bibliografico },
  } = await apiService.get(`${baseUrl}?biblionumber=${biblio}`)
  return book
}

// Move to utils
const checkValidSession = async () => {
  const validSession = await apiService.get(`/ping`).catch((error) => false)
  if (!validSession) {
    localStorage.removeItem("orex-session")
  }
  return validSession
}

function App({ isbns, biblios, library, language }) {
  //Internal??
  const [loading, setLoading] = useState(false)
  // Recomendations
  const [bookList, setBookList] = useState({ books: [], filters: [], took: 0 })

  // Locations
  const [locations, setLocations] = useRecoilState(locationAtom)

  // Actual book
  const [book, setBook] = useState({})
  // User Session
  const [user, setUser] = useStoredSession()

  const translations = setLanguage(language)

  const [, setHostname] = useRecoilState(hostnameAtom)

  useEffect(() => {
    const hostname = window.location.hostname
    setHostname(hostname)
  }, [setHostname])

  useEffect(() => {
    if (isbns || biblios) {
      setLoading(!loading)
      ;(async function () {
        let response = await getBookList(isbns, biblios, library)

        setLoading(false)
        const books = response.data.books

        const internal = books.filter((a) => a.biblioteca === library.prefix)
        const external = books.filter((a) => a.biblioteca !== library.prefix)

        const assembled = [...internal, ...external]

        const final = assembled.reduce((result, item) => {
          const currentISBN = item.isbn
          const exists = currentISBN
            ? result.find((book) => book.isbn === currentISBN)
            : false
          if (exists) {
            const removed = result.filter((book) => book.isbn !== currentISBN)
            if (exists.duplicates) {
              exists.duplicates.push(item)
            } else {
              exists.duplicates = [item]
            }
            return [...removed, exists]
          }
          return [...result, item]
        }, [])
        setUser({
          ...user,
          guid: GUID,
        })
        setBookList({
          ...response.data,
          books: final,
        })
      })()
    }
  }, [isbns, biblios])

  useEffect(async () => {
    let { data } = await getLocations()
    if (data) {
      const { cities, countries } = data
      setLocations({ cities, countries })
    }
  }, [])

  /* Ping for Session */
  useEffect(async () => checkValidSession(), [])

  return (
    <Container id="Books" maxWidth="sm">
      {loading && <SplashScreen library={library} language={language} />}
      {!loading && (
        <>
          <div style={{ maxWidth: "350px" }}>
            <Typography sx={{ fontWeight: 900, lineHeight: 1 }} variant="h6">
              {`${translations["headline"]}`}
            </Typography>
            <br />
            <Typography sx={{ fontWeight: 700, lineHeight: 1 }} variant="subtitle2">
              {library.label}
            </Typography>
          </div>

          <BookSlider
            language={language}
            library={library}
            filters={bookList.filters}
            bookList={bookList.books}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <BookStage
                user={user}
                language={language}
                library={library}
                filters={{ subject: bookList.filters, ...locations }}
                bookList={bookList.books}
              />
            </Suspense>
          </BookSlider>
        </>
      )}
      <Footer />
    </Container>
  )
}

export default App
