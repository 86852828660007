import React from "react"
import { TextFieldElement } from "react-hook-form-mui"

const FormField = ({ label, disabled, type, name, value }, ref) => {
  return (
    <TextFieldElement
      ref={ref}
      disabled={disabled}
      name={name}
      required
      sx={{ width: "100%", pb: 2 }}
      label={label}
      defaultValue={value || ""}
      variant="standard"
      type={type}
    />
  )
}

export default FormField
