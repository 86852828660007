/* eslint-disable no-unused-vars */
import React from "react"

import { Typography, Avatar } from "@mui/material"

import { COLORS } from "_constants"

const getColor = () => {
  const defined = {}
}

const BookAvatar = ({ title, sm }) => {
  return (
    <Avatar
      sx={{
        width: sm ? 36 : 96,
        height: sm ? 36 : 96,
        bgcolor: COLORS[Math.ceil(Math.random() * COLORS.length)],
      }}
    >
      <Typography style={{ fontWeight: 900 }} variant={sm ? "h5" : "h2"}>
        {title.substring(0, 1)}
      </Typography>
    </Avatar>
  )
}

const BookAvatarMemo = React.memo(BookAvatar)
export { BookAvatarMemo }

export default BookAvatar
