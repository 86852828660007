/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"

import SliderItem from "./SliderItem"

const Carousel = ({ bookList }) => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [factor, setFactor] = React.useState(0)
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

  const handleStepChange = (step) => setActiveStep(step)
  const handleCarouselSize = () => {
    const size = window.innerWidth / 1.5
    const value = (size - 200) / 1000

    setFactor(value <= 0.25 || value >= 1 ? 0.8 : value)
  }
  useEffect(() => {
    handleCarouselSize()
    window.addEventListener("resize", handleCarouselSize)

    return () => window.removeEventListener("resize", handleCarouselSize)
  }, [])

  return (
    <AutoPlaySwipeableViews
      axis={"x"}
      index={activeStep}
      onChangeIndex={handleStepChange}
      enableMouseEvents
      springConfig={{
        tension: 300,
        friction: 30,
        duration: "1.3s",
        delay: "0s",
      }}
      style={{ overflow: "hidden !important" }}
    >
      {bookList &&
        bookList
          // .filter((boo, item) => item === 7)
          // eslint-disable-next-line react/jsx-key
          .map((book) => <SliderItem key={book.isbn} factor={factor} book={book} />)}
    </AutoPlaySwipeableViews>
  )
}
export default Carousel
