/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import Downshift from "downshift"

import { TextField, Grid } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Button, Chip } from "@mui/material"

import { ButtonFlat } from "components"
import { apiService } from "config"
import { setLanguage } from "i18n"

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}))

const updateBlackList = async ({ biblios, id }) => {
  const baseUrl = `/blacklist/${id}`

  const { data } = await apiService.put(`${baseUrl}`, {
    biblios: biblios.toString(),
    id,
  })
  return data
}

const BiblioForm = ({ id, handleCommit, handleBlackList }) => {
  const translations = setLanguage("es-ES")

  const [inputValue, setInputValue] = React.useState("")
  const [selectedItem, setSelectedItem] = React.useState([])

  const classes = useStyles()

  useEffect(async () => {
    const baseUrl = `/blacklist/${id}`

    const { data } = await apiService.get(`${baseUrl}`)
    const blackList = data.biblios ? data.biblios.split(",") : []
    setSelectedItem(blackList)
    handleBlackList && handleBlackList(blackList, true)
  }, [])

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      const newSelectedItem = [...selectedItem]
      const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim())

      if (duplicatedValues !== -1) {
        setInputValue("")
        return
      }
      if (!event.target.value.replace(/\s/g, "").length) return

      newSelectedItem.push(event.target.value.trim())
      setSelectedItem(newSelectedItem)
      setInputValue("")
    }
    if (selectedItem.length && !inputValue.length && event.key === "Backspace") {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue("")
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  function handleInputChange(event) {
    setInputValue(event.target.value)
  }

  return (
    <div>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder: translations["add_biblios"],
          })
          return (
            <div>
              <TextField
                InputProps={{
                  startAdornment: selectedItem.map((item) => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus,
                  sx: { flexWrap: "wrap" },
                }}
                fullWidth
                variant="standard"
                id="tags"
                name="tags"
                placeholder="add Tags"
                label=""
                {...inputProps}
                sx={{ pb: 2, flexWrap: "wrap" }}
                type="number"
              />
            </div>
          )
        }}
      </Downshift>

      <>
        <ButtonFlat
          sx={{ mr: 2 }}
          size="small"
          variant="outlined"
          onClick={async () => {
            const blacklist = await updateBlackList({
              biblios: selectedItem,
              id,
            })
            handleCommit(false)
            handleBlackList && handleBlackList(selectedItem, false)
          }}
        >
          Update Blacklist
        </ButtonFlat>
        <ButtonFlat
          disabled={handleBlackList}
          sx={{ mr: 2 }}
          size="small"
          variant="outlined"
          onClick={() => handleCommit(false)}
        >
          Cancel
        </ButtonFlat>
      </>
    </div>
  )
}
export default BiblioForm
