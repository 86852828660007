import React from "react"

import { styled } from "@mui/material/styles"
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress"

const ProgressBarStyled = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#999" : "#333",
  },
}))

const ProgressBar = ({ value }) => {
  return (
    <span style={{ width: "75px" }}>
      <ProgressBarStyled variant="determinate" value={value} />
    </span>
  )
}

export default ProgressBar
