const esES = {
  headline: "Te puede interesar en:",
  more: "Ver más",
  added: "Añadido en",
  save: "Guardar",
  cancel: "Cancelar",
  validate: "Validar",
  quick_edit: "Editar",
  delete: "Eliminar",
  add: "Añadir",
  create: "Crear",
  external: "Link externo",
  subject: "Materia",
  city: "Ciudad",
  cover: "Carátula",
  score: "Recomendaciones",
  title: "Título",
  author: "Autor",
  pages: "Número de páginas",
  details: "Detalle",
  loading: "Cargando",
  image_heading: "Sugerencia",
  image_title: "Portada no disponible",
  no_results: "Sin Resultados...",
  results: "Resultados...",
  all_recomendations: "Todas las recomendaciones del usuario",
  library_recomendations: "Lectura recomendada por su biblioteca",
  check_recomendations: "Comprobar recomendaciones",
  for_recomendations: "Recomendaciones para",
  source_recomendations: "Recomendaciones Globales",
  this_book_has: "Este registro tiene ",
  recomendations: "Recomendaciones ",
  add_recomendations: "Añadir recomendaciones",
  login: "Iniciar sesión",
  logout: "Salir",
  username: "Nombre de Usuario",
  error_username: "No se puede crear un usuario con la misma clave/prefijo",
  password: "Contraseña",
  submit_login: "Enviar",
  grid_detail: "Lista / Detalles",
  admin_page: "Administración",
  about: "Acerca de",
  add_biblios: "Agregue números de registro(biblionumbers) y presione enter",
  blacklist: "Blacklist",
  local_blacklist: "Local Blacklist",
  all_users: "Todos los usuarios",
  create_users: "Crear usuarios",
  update_configuration: "Actualizar configuración",
  update_library: "Actualizar biblioteca",
  all_libraries: "Todas las bibliotecas",
  create_library: "Crear biblioteca",
  edit_library: "Editar biblioteca",
  error_library: "No se puede crear la biblioteca con el mismo prefijo...",
  error_recomendation: "No se puede crear un registro sin ISBN / ISSN",
  country: "País",
  ISBN: "ISBN",
  library: "Biblioteca",
}
export default esES
