import React, { lazy, Suspense } from "react"
import { Grid, Tabs, Tab, Box } from "@mui/material"

import { useStoredSession } from "hooks"
import { ADMIN, LOCAL_ADMIN } from "_constants"
import { setLanguage } from "i18n"

import Library from "./components/Library"
import Recomendations from "./components/Recomendations"
// import Map from "./Map"
import UpdateLibraryForm from "./components/UpdateLibraryForm"
import User from "./components/User"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Map = lazy(() => import("./Map"))
const HeatMap = lazy(() => import("./HeatMap"))

function TabPanel(props) {
  const { children, value, index, padding, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: padding === 0 ? 0 : 2 }}>{children}</Box>}
    </div>
  )
}

const Admin = () => {
  const [value, setValue] = React.useState(0)
  const [user] = useStoredSession()

  const translations = setLanguage(user.language)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Grid container sx={{ p: 0 }} style={{ width: "100%" }}>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        item
        style={{ width: "100%" }}
      >
        <Box align="start" justify="between" direction="row">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              size="small"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {user.type === LOCAL_ADMIN && (
                <Tab label={translations["blacklist"]} {...a11yProps(0)} />
              )}
              {user.type === ADMIN && (
                <Tab label={translations["all_libraries"]} {...a11yProps(0)} />
              )}
              {user.type === ADMIN && (
                <Tab
                  label={translations["source_recomendations"]}
                  {...a11yProps(1)}
                />
              )}

              {user.type === LOCAL_ADMIN && (
                <Tab label={translations["update_library"]} {...a11yProps(1)} />
              )}
              <Tab label={translations["all_users"]} {...a11yProps(2)} />

              {user.type === ADMIN || user.type === LOCAL_ADMIN ? (
                <Tab label={"Estadisticas"} {...a11yProps(3)} />
              ) : (
                <></>
              )}
            </Tabs>
          </Box>
          {user.type === LOCAL_ADMIN && (
            <TabPanel value={value} index={0}>
              <Library />
            </TabPanel>
          )}
          {user.type === ADMIN && (
            <TabPanel value={value} index={0}>
              <Library />
            </TabPanel>
          )}
          {user.type === ADMIN && (
            <TabPanel value={value} index={1}>
              <Recomendations />
            </TabPanel>
          )}
          {user.type === LOCAL_ADMIN && (
            <TabPanel value={value} index={1}>
              <UpdateLibraryForm libraryId={user.libraryId} />
            </TabPanel>
          )}
          <TabPanel value={value} index={2}>
            <User />
          </TabPanel>
          <TabPanel padding={2} value={value} index={3}>
            <h2>Estadisticas</h2>

            <Suspense fallback={<div>Loading...</div>}>
              <HeatMap />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <h2>Estadisticas Geograficas</h2>

              <Map />
            </Suspense>
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  )
}
export default Admin
