/* eslint-disable no-unused-vars */
import React from "react"
import ReactDOM from "react-dom"
import isEmpty from "lodash/isEmpty"
import { RecoilRoot } from "recoil"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import { create } from "jss"

import { Typography } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { StylesProvider, jssPreset } from "@mui/styles"
import theme from "./theme"

import App from "./App"

import { apiService } from "./config"

import styles from "./index.scss"

/* 
Handle Koha DOM
*/
let $$ = window.$
const DEFAULT_PLACEMENT = "#app, #menu"
const DEFAULT_ID = "books-widget"

const params = async (prefix) => {
  /* 1a chamada pra ir buscar as configs individuais dos clientes
  await call with prefix */
  const url = `/library/${prefix}`

  const response = await apiService.get(`${url}`).catch((error) => null)

  if (!response) return null
  const { data } = response
  return {
    platform: data.platform,

    library: {
      id: data.id,
      label: data.name,
      prefix: data.prefix,
      placement: data.placement || DEFAULT_PLACEMENT,
      resultsFrom: data.resultsFrom,
      useBlacklist: data.useBlacklist,
    },
    language: data.language,
    placement: data.placement || DEFAULT_PLACEMENT,
    client: "",
  }
}

const biblios = (prefix) => {
  const biblios = []
  const params = new URLSearchParams(window.location.search)
  const $$containers = $$(
    "#results, #listcontents, #checkoutst, #readingrec, #readingrec, #topissuest, #table_holdshistory"
  )
  const $$biblios = $$(".title_summary a, a.title")
  if ($$containers.length) {
    $$biblios.each((key, link) => {
      const $$link = $$(link)
      if ($$link.hasClass("title")) {
        const $$linkString = $$link.first().attr("href") || $$link.attr("href")
        if ($$linkString.includes("biblionumber"))
          biblios.push(
            prefix +
              $$linkString
                .split("?")
                .map((item) => item.split("&"))
                .flat()
                .find((item) => item.includes("biblionumber"))
                .split("=")[1]
          )
      }
    })
  } else {
    biblios.push(prefix + params.get("biblionumber"))
  }
  return biblios.toString()
}

const isbns = () => {
  return $$('[property="isbn"]').text()
}

function init({ isbns, biblios, placement, library }) {
  let widget = document.createElement("div")
  let parent = document.querySelector(placement).parentNode

  if (isbns || biblios) {
    if (
      (isbns || isEmpty(isbns)) &&
      biblios &&
      !biblios.split("_").includes("null")
    ) {
      ReactDOM.render(
        <React.StrictMode>
          <App library={library} isbns={isbns} biblios={biblios} />
        </React.StrictMode>,
        parent.insertBefore(widget, document.querySelector(placement))
      )
      widget.classList.add("Widget")
    }
  }
}

function initShadowDOM({ isbns, biblios, placement, library }) {
  let widget = document.querySelector(placement)

  if (!widget) return null

  const app = document.createElement("div")
  app.id = DEFAULT_ID
  const appRoot = widget.appendChild(app)

  const shadowRoot = appRoot.attachShadow({ mode: "open" })

  const style = document.createElement("style")
  const emotionRoot = document.createElement("style")
  const mountPoint = document.createElement("div")

  shadowRoot.appendChild(emotionRoot)
  const reactRoot = shadowRoot.appendChild(mountPoint)

  const jss = create({
    ...jssPreset(),
    insertionPoint: reactRoot,
  })

  const cache = createCache({
    key: "css",
    prepend: true,
    container: emotionRoot,
  })

  ReactDOM.render(
    isbns || biblios ? (
      <React.StrictMode>
        <StylesProvider jss={jss}>
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <RecoilRoot>
                <App library={library} isbns={isbns} biblios={biblios} />
              </RecoilRoot>
            </ThemeProvider>
          </CacheProvider>
        </StylesProvider>
      </React.StrictMode>
    ) : (
      <Typography>Configuration error</Typography>
    ),
    mountPoint
  )

  style.textContent = styles
  shadowRoot.appendChild(style)
}

window.OREX = {
  INIT: async function (prefix) {
    const { platform, library, language, placement } = await params(prefix)
    init({
      platform,
      library,
      language,
      prefix,
      isbns: isbns(),
      biblios: biblios(prefix),
      placement,
    })
  },
  INIT_SHADOW: async function (prefix) {
    $$ = window.$

    const response = await params(prefix).catch((error) => null)
    if (!response) return null
    const { platform, library, language, placement } = response
    initShadowDOM({
      platform,
      library,
      language,
      prefix,
      isbns: $$ && isbns(),
      biblios: $$ && biblios(`${prefix}_`),
      placement,
    })
  },
}
