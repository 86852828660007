/* eslint-disable no-unused-vars */
import React from "react"
import { DataGrid } from "@mui/x-data-grid"

const LibraryList = ({ rows, columns }) => {
  return (
    <DataGrid
      disableSelectionOnClick
      resizeable
      autoHeight={true}
      sortable
      border="horizontal"
      pageSize={10}
      paginate
      columns={columns}
      rows={rows}
    />
  )
}
export default LibraryList
