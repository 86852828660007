/* eslint-disable no-unused-vars */
import React from "react"
import { Grid, Typography } from "@mui/material"
import TextareaAutosize from "@mui/base/TextareaAutosize"

import { useGetAllRecomendations } from "hooks"

const AllRecomendations = () => {
  const [{ result }] = useGetAllRecomendations()

  return (
    <Grid container sx={{ p: 0 }} style={{ width: "100%" }}>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        item
        style={{ width: "100%" }}
      >
        <Grid
          justifyContent="space-between"
          alignItems="flex-start"
          container
          style={{ width: "100%" }}
        >
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "900" }}>
              Global Recomendations
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <TextareaAutosize
          maxRows={24}
          defaultValue={result}
          style={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  )
}
export default AllRecomendations
