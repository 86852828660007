import React, { useState, useEffect, useRef, useCallback } from "react"
import { Grid, Typography, Divider, IconButton } from "@mui/material"

import { Delete } from "@mui/icons-material"

import { apiService } from "config"
import { UseGetRecomendedBooks } from "hooks"
import { clean, getShortYear } from "utils"

import { Spinner } from "components"

const PAGE_INCREMENTS = 10

const Books = ({ editing }) => {
  const [pageNum, setPageNum] = useState(1)
  const bookList = UseGetRecomendedBooks({ editing, prefix: editing.prefix })
  const [page, setPage] = useState([])
  let hasMore = true

  useEffect(() => {
    hasMore = bookList.length > PAGE_INCREMENTS
    setPage(bookList.filter((i, k) => k <= PAGE_INCREMENTS))
  }, [bookList, editing])

  const observer = useRef()
  const lastBookElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newCount = pageNum + 1
          const size = newCount * PAGE_INCREMENTS
          const books = bookList.filter((i, k) => k <= size)
          setPageNum(newCount)
          setPage(books)
          hasMore = true
        }
      })
      if (node) observer.current.observe(node)
    },
    [hasMore, bookList]
  )
  return bookList.length ? (
    page.map((item, index) => {
      return item ? (
        <div ref={page.length === index + 1 ? lastBookElementRef : null} key={index}>
          <Grid container style={{ minHeight: "100px", paddingTop: "1rem" }}>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: "900" }} variant="h4">
                {getShortYear(item._source.copyrightdate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <a
                rel="noreferrer"
                target={"_blank"}
                href={`//${item._source.red}.orex.es/cgi-bin/koha/opac-detail.pl?biblionumber=${item._source.biblionumber}`}
              >
                <Typography sx={{ fontWeight: "900" }} variant="h6">
                  {clean(item._source.title)}
                </Typography>
              </a>
              <Typography variant="subtitle2">{item._source.author}</Typography>
              <Typography variant="subtitle1">
                {item._source.publishercode}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={async () => {
                  const biblio = item._source.biblionumber

                  if (confirm(`Delete ${biblio}`)) {
                    const newBooks = bookList.filter(
                      (book) => book._source.biblionumber !== biblio
                    )

                    const updatedRecomendations = newBooks.map(
                      (book) => book._source.biblionumber
                    )
                    const { data } = await apiService.put(`/user-recomendations`, {
                      biblios: updatedRecomendations.toString(),
                      id: editing.id,
                    })

                    if (data) {
                      setPage(newBooks)
                    }
                  }
                }}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
        </div>
      ) : (
        <div>Biblio erroneo</div>
      )
    })
  ) : (
    <Spinner />
  )
}
export default Books
