/* eslint-disable no-unused-vars */
import React from "react"
import { MenuItem, Box, Chip, OutlinedInput, InputLabel } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import { Cancel } from "@mui/icons-material"

import Select from "@mui/material/Select"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  disablePortal: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const SelectFilter = ({ multiple, filters, title, value, handleOptions, id }) => {
  return (
    <div style={{ width: "100%" }}>
      <FormControl sx={{ my: 2, width: "100%" }}>
        <InputLabel id="demo-multiple-name-label" sx={{ fontSize: "12px" }}>
          {title}
        </InputLabel>
        <Select
          autoWidth
          size="small"
          clear={true}
          multiple={multiple}
          placeholder={"Select"}
          value={value[id] || []}
          onChange={(evt) => {
            const {
              target: { value },
            } = evt
            handleOptions(value, id)
          }}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {typeof value[id] === "object" ? (
                selected.map((v) => {
                  return (
                    <Chip
                      variant="outlined"
                      key={v}
                      label={filters.find((filter) => filter.id === v).value}
                      size="small"
                      deleteIcon={
                        <Cancel
                          onMouseDown={(evt) => {
                            evt.stopPropagation()
                            const values = value[id].filter((_v) => _v !== v)
                            handleOptions(values, id)
                          }}
                        />
                      }
                      onDelete={() => null}
                    />
                  )
                })
              ) : (
                <div>{value[title]}</div>
              )}
            </Box>
          )}
        >
          {filters.map(({ id, value }, key) => (
            <MenuItem
              key={id}
              autoFocus={key === 0}
              sx={{ fontSize: "12px" }}
              value={id}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectFilter
