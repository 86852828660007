/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { useRecoilState } from "recoil"
import {
  FormContainer,
  TextFieldElement,
  CheckboxElement,
} from "react-hook-form-mui"
import { Alert, Typography, Button, Grid, NativeSelect } from "@mui/material"

import { apiService } from "config"
import { setLanguage } from "i18n"
import { ButtonFlat } from "components"
import { useGetAllLibraries, useStoredSession } from "hooks"

import { locationAtom } from "atoms"

const NativeDropDown = ({ values, label, selected, handleOnChange }) => {
  return (
    <>
      <Typography>{label}</Typography>
      <NativeSelect required onChange={({ target }) => handleOnChange(target.value)}>
        {values.map((value, key) => (
          <option
            selected={value.id === selected}
            key={`${key}-native`}
            value={value.id}
          >
            {value.label}
          </option>
        ))}
      </NativeSelect>
    </>
  )
}

const LibraryForm = ({ handleSetMode, editLibrary, handleCancelEditLibrary }) => {
  const [{ countries, cities }] = useRecoilState(locationAtom)
  const [user] = useStoredSession()
  const [libraries, setNewLibraries] = useGetAllLibraries()

  const [status, setStatus] = useState(false)
  const [formValues, setFormValues] = useState(
    editLibrary || {
      name: "",
      prefix: "",
      language: "es-ES",
      placement: "#app, #menu, #ulactioncontainer",
      resultsFrom: false,
      useBlacklist: false,
      countryISO: 1,
      cityISO: 1,
    }
  )

  const translations = setLanguage(user.language)

  const handleStateUpdate = (mode) => {
    handleSetMode(mode)
    handleCancelEditLibrary(null)
  }

  const handleUpdateLibrary = async (data, formValues) => {
    const baseUrl = `/library`
    const results = await apiService.put(`${baseUrl}`, {
      ...data,
      cityISO: formValues.cityISO,
      countryISO: formValues.countryISO,
    })
    if (results) {
      const libraryId = results.data && results.data.id
      const updatedLibrary = libraries.filter((library) => library.id !== libraryId)
      setNewLibraries([...updatedLibrary, results.data])
      handleStateUpdate("table")
    }
  }

  const handleCreateLibrary = async (data, formValues) => {
    const baseUrl = `/library`
    const results = await apiService
      .post(`${baseUrl}`, {
        ...data,
        cityISO: formValues.cityISO,
        countryISO: formValues.countryISO,
      })
      .catch((error) => setStatus("error"))
    if (results) {
      handleStateUpdate("table")
      return setNewLibraries([...libraries, results.data])
    }
  }

  return (
    <>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        container
        style={{ width: "100%" }}
      >
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: "900" }}>
            {editLibrary
              ? translations["edit_library"]
              : translations["create_library"]}
          </Typography>
        </Grid>
        <Grid item>
          {
            <Button disableElevation onClick={() => handleStateUpdate("table")}>
              {translations["cancel"]}
            </Button>
          }
        </Grid>
      </Grid>
      <FormContainer
        defaultValues={formValues}
        onSuccess={(data) =>
          editLibrary
            ? handleUpdateLibrary(data, formValues)
            : handleCreateLibrary(data, formValues)
        }
      >
        <TextFieldElement
          helperText="Library Name."
          id="name"
          name="name"
          required
          sx={{ width: "100%", pb: 2 }}
          label={"Name"}
          variant="standard"
          type={"text"}
        />
        <TextFieldElement
          id="prefix"
          helperText="Library Prefix."
          name={"prefix"}
          required
          sx={{ width: "100%", pb: 2 }}
          label={"Prefix"}
          variant="standard"
          type={"text"}
        />

        <TextFieldElement
          required
          id="placement"
          helperText="Widget default placement."
          name={"placement"}
          sx={{ width: "100%", pb: 2 }}
          label={"Placement"}
          variant="standard"
          type={"text"}
        />

        <TextFieldElement
          required
          id="language"
          helperText="Library Default Language."
          name={"language"}
          sx={{ width: "100%", pb: 2 }}
          label={"Language"}
          variant="standard"
          type={"text"}
        />

        <NativeDropDown
          id="cityISO"
          selected={formValues.cityISO}
          name="cityISO"
          sx={{ width: "100%", pb: 2 }}
          label={"City"}
          variant="standard"
          values={cities}
          handleOnChange={(value) =>
            setFormValues({
              ...formValues,
              cityISO: +value,
            })
          }
        />

        <NativeDropDown
          id="countryISO"
          selected={formValues.countryISO}
          name="countryISO"
          sx={{ width: "100%", pb: 2 }}
          label={"Country"}
          variant="standard"
          values={countries}
          handleOnChange={(value) =>
            setFormValues({ ...formValues, countryISO: +value })
          }
        />

        <div style={{ width: "100%", pb: 2 }}>
          <CheckboxElement
            id="resultsFrom"
            name={"resultsFrom"}
            sx={{ width: "100%", pb: 2 }}
            label={"Only show results from my library"}
            variant="standard"
          />

          <CheckboxElement
            id="useBlacklist"
            name={"useBlacklist"}
            sx={{ width: "100%", pb: 2 }}
            label={"Blacklist just from my library"}
            variant="standard"
          />
        </div>

        <ButtonFlat disableElevation type="submit" size="small">
          {editLibrary
            ? translations["update_library"]
            : translations["create_library"]}
        </ButtonFlat>
        {status && <Alert severity={status}>{translations["error_library"]}</Alert>}
      </FormContainer>
    </>
  )
}
export default LibraryForm
