/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { TextField, Grid, Typography } from "@mui/material"
import { Edit, Preview } from "@mui/icons-material"

import { BiblioForm, ButtonFlat } from "components"
import Books from "features/Recomendations/Books"
import { setLanguage } from "i18n"

const LocalBlackList = ({ handleSetBlackList, id, prefix }) => {
  const translations = setLanguage("es-ES")

  const [blackList, setBlackList] = useState(null)
  const [edit, setEdit] = useState(true)
  const localBlackList = (blackList, _edit) => {
    setBlackList({ biblios: blackList.toString() })
    !_edit && setEdit(false)
  }
  return (
    <Grid container sx={{ p: 0 }} style={{ width: "100%" }}>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        item
        style={{ width: "100%" }}
      >
        <Grid
          justifyContent="space-between"
          alignItems="flex-start"
          container
          style={{ width: "100%" }}
        >
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "900" }}>
              {translations["local_blacklist"]}
            </Typography>
          </Grid>
          <Grid item>
            <ButtonFlat disableElevation onClick={() => setEdit(!edit)}>
              {!edit ? <Preview /> : <Edit />}
              {!edit ? "Editar" : "Ver"} {translations["blacklist"]}
            </ButtonFlat>
          </Grid>
        </Grid>
        {edit && (
          <BiblioForm
            handleCommit={handleSetBlackList}
            handleBlackList={localBlackList}
            id={id}
          />
        )}
        {blackList && !edit && <Books editing={{ ...blackList, prefix }} />}
      </Grid>
    </Grid>
  )
}
export default LocalBlackList
