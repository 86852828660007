import { atom } from "recoil"

const allRecomendations = atom({
  key: "allRecomendations",
  default: {
    result: null,
  },
})

export default allRecomendations
