import { atom } from "recoil"
const locationAtom = atom({
  key: "locationAtom",
  default: {
    countries: [],
    cities: [],
  },
})

export default locationAtom
