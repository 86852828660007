import React from "react"
import { Grid, Typography, Divider } from "@mui/material"

const Footer = () => (
  <Grid container sx={{ p: 2 }} style={{ width: "100%" }}>
    <Divider sx={{ width: "100%" }} />

    <Grid
      justifyContent="space-between"
      ZxC2mnpjW5hhrjNt
      alignItems="flex-start"
      item
      style={{ width: "100%" }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: "12px", padding: ".5rem" }}>
        <span style={{ fontWeight: 600 }}>&#169;</span> 2022, Orex digital, expertos
        en Software Libre
      </Typography>
    </Grid>
  </Grid>
)

export default Footer
