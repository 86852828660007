/* eslint-disable no-unused-vars */
import React from "react"
import { MenuItem, Typography, Grid } from "@mui/material"
import { Login, Logout } from "@mui/icons-material/"

import { useStoredSession, useDropDownMenu } from "../../hooks"
import { apiService } from "../../config"
import { setLanguage } from "../../i18n"

const defaultState = {
  user: null,
  login: false,
  params: {
    username: "",
    password: "",
  },
}

const SessionButton = ({ handleClose, language }) => {
  const translations = setLanguage(language)
  const [user, setUser] = useStoredSession()

  return (
    <MenuItem
      key={"login-item"}
      onClick={async () => {
        await apiService.get(`/logout`)
        setUser({
          ...user,
          username: null,
        })
        handleClose()
      }}
    >
      {" "}
      <Grid justifyContent="flex-start" sx={{ width: "100%" }} container>
        <Grid sx={{ mr: 1 }} item>
          {!user.username ? <Login fontSize="small" /> : <Logout fontSize="small" />}{" "}
        </Grid>
        <Grid item>
          <Typography fontSize="small">
            {!user.username ? translations["login"] : translations["logout"]}
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>
  )
}

export default SessionButton
