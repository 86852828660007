import React from "react"
import { Box, Typography } from "@mui/material"

import "./Footer.scss"

const Footer = () => {
  return (
    <Box align="start" justify="between" direction="row">
      <hr />
      <img
        className="Footer_logo"
        style={{ width: "75px", padding: ".5rem" }}
        src="https://orex.es/wp-content/uploads/2020/12/cropped-CSIC-DIGITAL09122014orex-logo.png"
      />
      <Typography sx={{ fontWeight: 400, fontSize: "12px", padding: ".5rem" }}>
        <span style={{ fontWeight: 600 }}>&#169;</span> 2022, Orex digital, expertos
        en Software Libre
      </Typography>
    </Box>
  )
}
export default Footer
