import React, { lazy, useState, Suspense } from "react"

import Typography from "@mui/material/Typography"

import { apiService } from "config"

import { useDropDownMenu, useStoredSession } from "hooks"
import { setLanguage } from "i18n"

import Carousel from "./components"
import { ButtonFlat } from "components"

const BookLayer = lazy(() => import("../BookDialog"))

const updateLog = async (guid) => {
  if (!guid) return false
  const url = `/log-recomendations`
  return await apiService.get(`${url}?guid=${guid}`)
}

function BookSlider({ bookList, book, children, library, language }) {
  const [show, setShow] = useState()
  const [, setPage] = useDropDownMenu("grid")
  const [user] = useStoredSession()

  const translations = setLanguage(language)

  return (
    <>
      {bookList.length > 0 && <Carousel bookList={bookList} />}
      {bookList.length <= 0 && (
        <Typography
          noWrap
          sx={{ fontWeight: 300, my: 4 }}
          variant="h6"
        >{`${translations["no_results"]}`}</Typography>
      )}
      <ButtonFlat
        fullWidth
        sx={{ my: 2 }}
        variant="outlined"
        size="small"
        label={translations["more"]}
        onClick={() => {
          setShow(true)

          updateLog(user.guid)
          if (bookList.length > 0) {
            setPage("grid")
          } else {
            setPage("login")
          }
        }}
      >
        {translations["more"]}
      </ButtonFlat>

      {show && (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            {/* Should move that */}
            <BookLayer
              translations={translations}
              language={language}
              library={library}
              show={show}
              setShow={setShow}
              book={book}
            >
              {children}
            </BookLayer>
          </Suspense>
        </>
      )}
    </>
  )
}

export default BookSlider
