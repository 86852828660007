/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from "react"
import { useRecoilState } from "recoil"

import { Box, Avatar, Typography, IconButton, Link, Grid } from "@mui/material"

import hostnameAtom from "atoms/hostname"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { WorkspacePremium, LocalLibrary, Public } from "@mui/icons-material"

import ProgressBar from "components/ProgressBar"
import { setLanguage } from "i18n"
import { clean } from "utils"
import { BookAvatarMemo } from "components/BookAvatar/BookAvatar"

import { visuallyHidden } from "@mui/utils"

import { HEADER_CELLS } from "../BookGrid.Constants"

export const TooltipPatch = ({ placement, language, children, title }) => {
  return (
    <div style={{ position: "relative" }}>
      <div title={title}>{children}</div>
    </div>
  )
}

export const BookAvatarItem = ({ book, select, language, modal }) => {
  const thumbnail =
    book.google.items &&
    book.google.items[0].volumeInfo.imageLinks &&
    book.google.items[0].volumeInfo.imageLinks.thumbnail
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton onClick={() => (modal ? select(book) : null)}>
        <>
          {thumbnail ? (
            <Avatar src={thumbnail} />
          ) : (
            <BookAvatarMemo sm title={book.title} />
          )}
        </>
      </IconButton>
      <>
        {book.curated && (
          <TooltipPatch
            placement="right"
            language={language}
            title="Recomendado por bibliotecarios"
          >
            <IconButton>
              <WorkspacePremium fontSize="small" />
            </IconButton>
          </TooltipPatch>
        )}
        {book.internal && (
          <TooltipPatch placement="right" language={language} title="Interno">
            <LocalLibrary fontSize="small" />
          </TooltipPatch>
        )}
        {!book.internal && (
          <TooltipPatch placement="right" language={language} title="Externo">
            <Public fontSize="small" />
          </TooltipPatch>
        )}
      </>
    </div>
  )
}

export const BookLinkItem = ({ book }) => {
  const { biblioteca } = book
  const [hostname] = useRecoilState(hostnameAtom)
  const isIntra = hostname.includes("intra")
  const intraUrl = `https://${biblioteca}-intra.orex.es/cgi-bin/koha/catalogue/detail.pl?biblionumber=${book.biblionumber}`
  const opacURL = `https://${biblioteca}.orex.es/cgi-bin/koha/opac-detail.pl?biblionumber=${book.biblionumber}`

  return (
    <Link
      target="_blank"
      style={{ minWidth: "200px" }}
      href={isIntra ? intraUrl : opacURL}
      rel="noreferrer"
    >
      {/* textOverflow: 'ellipsis' */}
      <Typography variant="caption">{clean(book.title)}</Typography>
    </Link>
  )
}

export const CanonicalLinkItem = ({ book }) => {
  const canonicalLink =
    book.google && book.google.totalItems && book.google.items[0].volumeInfo
      ? book.google.items[0].volumeInfo.canonicalVolumeLink
      : null
  return canonicalLink ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <a
        style={{ height: "25px", textAlign: "center" }}
        target="_blank"
        href={canonicalLink}
        rel="noreferrer"
      >
        <img
          src="//ro.dolfo.me/orex/static/assets/google-books-logo.svg"
          width={75}
        />

        {/* <Link /> */}
      </a>
    </div>
  ) : (
    <div />
  )
}

export const EnhancedTableHeader = ({
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  language,
}) => {
  const tranlations = setLanguage(language)

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{ width: "100%" }}>
      <TableRow sx={{ width: "100%" }}>
        {HEADER_CELLS.map((headerCell, index) => (
          <TableCell
            key={headerCell.id}
            align={6 === index ? "right" : "left"}
            padding={headerCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headerCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headerCell.id}
              direction={orderBy === headerCell.id ? order : "asc"}
              onClick={createSortHandler(headerCell.id)}
            >
              <Typography fontWeight={600} fontSize={12} noWrap>
                {tranlations[headerCell.label]}
              </Typography>
              {orderBy === headerCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  )
}

export const ScoreBarItem = ({ book }) => (
  <div
    style={{
      display: "flex",
      width: "100%",
      maxWidth: "100px",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Typography variant="h6" sx={{ pr: 2 }} style={{ fontWeight: 300 }}>
      {book.score || 0}
    </Typography>
    <ProgressBar value={book.score || 0} />
  </div>
)

export const FlagItem = ({ countries, countryId }) => {
  const country = countries.find((c) => c.id === countryId)
  const iso2 = country ? country.iso2 : "xx"
  const flagURL = `//ro.dolfo.me/orex/countries/${iso2.toLowerCase()}.svg`
  return countryId > 1 && <img style={{ width: "25px" }} src={flagURL} />
}

export const CollapsedBookList = ({
  duplicates,
  select,
  language,
  filters,
  library,
}) => (
  <Table size="small" aria-label="purchases">
    <TableBody>
      {duplicates.map((b, k) => {
        return (
          <TableRow key={`${b.isbn}-${k}`}>
            <TableCell component="th" scope="row">
              <BookAvatarItem book={b} select={select} language={language} />
            </TableCell>
            <TableCell>
              <BookLinkItem book={b} />
            </TableCell>
            <TableCell>
              <FlagItem countries={filters.countries} countryId={b.country} />
            </TableCell>
            <TableCell align="right">
              <Typography variant="caption">{b.library}</Typography>
            </TableCell>

            <TableCell align="right">
              <CanonicalLinkItem book={b} />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  </Table>
)

export const Legend = ({ library }) => (
  <Grid container spacing={2} columns={12} sx={{ pt: 2 }}>
    <Grid item xs={3}>
      <div style={{ display: "flex" }}>
        <Typography fontSize={12}>Leyenda</Typography>
      </div>
    </Grid>
    <Grid item xs={3}>
      <div style={{ display: "flex" }}>
        <WorkspacePremium sx={{ pr: 2 }} fontSize="x-small" />
        <Typography fontSize={12}>Lectura recomendada por su biblioteca</Typography>
      </div>
    </Grid>
    <Grid item xs={3}>
      <div style={{ display: "flex" }}>
        <LocalLibrary sx={{ pr: 2 }} fontSize="x-small" />
        <Typography fontSize={12}>Disponible en {library.label}</Typography>
      </div>
    </Grid>
    <Grid item xs={3}>
      <div style={{ display: "flex" }}>
        <Public sx={{ pr: 2 }} fontSize="x-small" />
        <Typography fontSize={12}>Recomendaciones en otras bibliotecas</Typography>
      </div>
    </Grid>
  </Grid>
)
