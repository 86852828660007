import React, { useEffect } from "react"
import { Alert, IconButton } from "@mui/material"
import { Error, Close } from "@mui/icons-material"

const AlertMessage = ({ children, severity }) => {
  const [visible, setVisible] = React.useState(false)
  useEffect(() => {
    setVisible(severity ? true : false)
  }, [severity])
  return visible ? (
    <Alert
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setVisible(false)
          }}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
      icon={<Error fontSize="inherit" />}
      sx={{ width: "100%" }}
      severity={severity}
    >
      {children}
    </Alert>
  ) : (
    <div />
  )
}

export default AlertMessage
