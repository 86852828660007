import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { apiService } from "../config"
import { useStoredSession } from "../hooks"

import { userListAtom } from "../atoms"

const useGetAllUsers = () => {
  const [user] = useStoredSession()

  const [users, setUsers] = useRecoilState(userListAtom)
  const setNewUsers = (newusers) => {
    return setUsers(newusers)
  }

  useEffect(async () => {
    const baseUrl = user.type !== "ADMIN" ? `/local-user/${user.libraryId}` : `/user`
    const results = await apiService.get(`${baseUrl}`)

    return setUsers(results.data)
  }, [])

  return [users, setNewUsers]
}

export default useGetAllUsers
