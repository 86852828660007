import React from "react"
import { Grid, Alert, IconButton, Typography } from "@mui/material"
import { FormContainer } from "react-hook-form-mui"
import { Error, Close } from "@mui/icons-material"

import { ADMIN, LOCAL_ADMIN } from "_constants"

import { apiService } from "config"
import { setLanguage } from "i18n"

import { useStoredSession } from "hooks"
import { ButtonFlat } from "components"
import FormField from "./FormField"

const defaultState = {
  user: null,
  login: false,
  processed: false,
  params: {
    username: "",
    password: "",
  },
}

const OnSubmitApi = async ({ username, password, prefix }) => {
  const url = `/login`
  return await apiService.post(`${url}`, { username, password, prefix })
}

const LoginFormInputs = ({ handleOnSubmit, prefix, translations, status }) => (
  <FormContainer
    defaultValues={{ prefix, username: "", password: "" }}
    onSuccess={(data) => {
      handleOnSubmit(data)
    }}
  >
    <FormField
      name={"prefix"}
      disabled
      label="Prefix:"
      value={prefix}
      type="standard"
    />
    <FormField
      name={"username"}
      label={translations["username"]}
      value={status.params.username}
      type="standard"
    />
    <FormField
      name={"password"}
      label={translations["password"]}
      value={status.params.password}
      type="password"
    />
    <div>
      <ButtonFlat disableElevation type="submit" size="small">
        {translations["submit_login"]}
      </ButtonFlat>
    </div>
  </FormContainer>
)

const LoginForm = ({ prefix, language }) => {
  const translations = setLanguage(language)

  const [status, setStatus] = React.useState(defaultState)

  const [, setUser] = useStoredSession()

  const onSubmit = async (params) => {
    const { data } = await OnSubmitApi(params)

    if (data.local) {
      setUser({
        username: data.local.email,
        id: data.local.id,
        libraryId: data.local.libraryId,
        prefix,
        // Should be set on server
        admin: data.local.type === ADMIN || data.local.type === LOCAL_ADMIN,
        type: data.local.type,
      })
      setStatus({
        ...status,
        user: data.user,
        login: !!data.user,
        processed: true,
      })
    } else {
      setStatus({
        ...status,
        user: null,
        login: false,
        processed: true,
      })
    }

    return data
  }

  return (
    <Grid sx={{ p: 4 }} container spacing={2}>
      <Grid item xs={8}>
        <Typography fontWeight={900} variant="h6">
          Login
        </Typography>
        <LoginFormInputs
          translations={translations}
          handleOnSubmit={onSubmit}
          status={status}
          prefix={prefix}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      {status.processed ? (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setStatus({
                  ...status,
                  processed: false,
                })
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          icon={<Error fontSize="inherit" />}
          sx={{ width: "100%" }}
          severity={status.login ? "success" : "error"}
        >
          {status.login
            ? `Login sucessful ${status.user.firstname} ${status.user.surname}`
            : "Login unsucessful"}
        </Alert>
      ) : (
        <div />
      )}
    </Grid>
  )
}
// ZxC2mnpjW5hhrjNt
// userrodolfo
// STthXvhJBnq95BF
export default LoginForm
