/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Grid, Typography, Button } from "@mui/material"
import { Delete, Book, Edit } from "@mui/icons-material"

import { ADMIN, LOCAL_ADMIN } from "_constants"
import { apiService } from "config"
import { setLanguage } from "i18n"

import { useStoredSession, useGetAllLibraries } from "hooks"

import LibraryList from "./Table"
import LibraryForm from "./LibraryForm"
import BlackList from "./BlackList"
import { LocalBlackList } from "./BlackList"

const Library = () => {
  const [user] = useStoredSession()
  const [libraries, setNewLibrary] = useGetAllLibraries()
  const [editLibrary, setEditLibrary] = useState(null)

  const [blackList, setBlacklist] = useState(null)
  const [mode, setMode] = useState("table")

  const isAdmin = user.type === ADMIN
  const isLocalAdmin = user.type === LOCAL_ADMIN

  const translations = setLanguage(user.language)

  const handleDeleteLibrary = async (id) => {
    const baseUrl = `/library/${id}`
    const results = await apiService.delete(`${baseUrl}`)
    if (results) {
      const newLibrariesList = libraries.filter((library) => library.id !== id)
      return setNewLibrary(newLibrariesList)
    }
  }

  const handleEditLibrary = (id) => {
    setMode("form")
    setEditLibrary(libraries.find((library) => library.id === id))
  }

  const cancelEditLibrary = (id) => {
    setMode("table")
    setEditLibrary(libraries.find((library) => library.id === id))
  }

  const LibrariesTableConfig = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "prefix",
      headerName: "Prefix",
      primary: false,
    },
    {
      field: "placement",
      headerName: "Placement",
      primary: false,
    },
    {
      field: "useBlacklist",
      headerName: "Use Blacklist",
      primary: false,
    },
    {
      field: "resultsFrom",
      headerName: "Results From",
      primary: false,
    },
    {
      field: "id",
      headerName: "Delete",
      flex: 0.25,
      minWidth: 150,
      primary: false,
      renderCell: (row) => {
        return (
          <div>
            <Button onClick={() => handleEditLibrary(row.id)}>
              <Edit />
            </Button>
            <Button
              onClick={() => {
                return handleDeleteLibrary(row.id)
              }}
            >
              <Delete />
            </Button>
            <Button
              onClick={() => {
                setBlacklist(row.id)
              }}
            >
              Create / Update BlackList
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <Grid container sx={{ p: 0 }} style={{ width: "100%" }}>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        item
        style={{ width: "100%" }}
      >
        {isAdmin && (
          <Grid
            justifyContent="space-between"
            alignItems="flex-start"
            container
            style={{ width: "100%" }}
          >
            {mode !== "form" && (
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "900" }}>
                  {translations["all_libraries"]}
                </Typography>
              </Grid>
            )}

            <Grid item>
              {mode === "table" && !blackList && (
                <Button disableElevation onClick={() => setMode("form")}>
                  <Book /> {translations["create_library"]}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        {/* Table */}
        {isAdmin && mode === "table" && !blackList && (
          <LibraryList rows={libraries} columns={LibrariesTableConfig} />
        )}

        {isAdmin && blackList && (
          <BlackList id={blackList} handleSetBlackList={setBlacklist} />
        )}
        {isLocalAdmin && (
          <LocalBlackList
            prefix={user.prefix}
            id={user.libraryId}
            blackList={blackList}
            handleSetBlackList={setBlacklist}
          />
        )}

        {mode === "form" && (
          <LibraryForm
            editLibrary={editLibrary}
            handleCancelEditLibrary={cancelEditLibrary}
            handleSetMode={setMode}
          />
        )}
      </Grid>
    </Grid>
  )
}
export default Library
