import React from "react"
import { Grid } from "@mui/material"

import BookDetail from "features/BookDetail"

import { BackButton } from "components"

const DetailPage = ({
  books,
  selected,
  setSelected,
  position,
  setPosition,
  googleBookInfo,
  googleBookSearch,
  hasImages,
  goToPrevious,
  goToNext,
}) => (
  <Grid container sx={{ p: 2 }} style={{ width: "100%" }}>
    <Grid
      justifyContent="space-between"
      alignItems="flex-start"
      item
      style={{ width: "100%" }}
    >
      <BackButton
        handleOnClick={() => {
          setSelected(null)
          setPosition(1)
        }}
      />
    </Grid>
    <BookDetail
      size={books.length - 1}
      selected={selected}
      position={position}
      setSelected={setSelected}
      googleBookInfo={googleBookInfo}
      googleBookSearch={googleBookSearch}
      hasImages={hasImages}
      goToPrevious={goToPrevious}
      goToNext={goToNext}
    />
  </Grid>
)

export default DetailPage
