import React from "react"
import { Grid, Typography } from "@mui/material"

const AboutPage = () => (
  <Grid container sx={{ p: 2 }}>
    <Grid item md={3} sx={{ pr: 4 }}>
      <Typography variant="h4">Sobre Orex Analytics</Typography>
      <Typography variant="subtitle2">
        Orex analytics recomendación es un novedoso y único servicio web de
        recomendación que se integra con su catálogo, ofreciendo a sus usuarios
        recomendaciones relevantes a través de un avanzado sistema de inteligencia
        artificial, que analiza millones de préstamos indexados, complementado por
        las recomendaciones de profesionales.
      </Typography>
    </Grid>
    <Grid container md={8}>
      <Grid item md={6} sx={{ pr: 4 }}>
        <Typography variant="h6" sx={{ pb: 1 }}>
          Para la biblioteca
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          Orex analytics recomendación es un servicio imprescindible para las
          bibliotecas del siglo XXI, pudiendo ofrecer a sus usuarios los servicios de
          recomendación a los que están acostumbrados y que tanto se valora en
          recursos web de tanto éxito como Amazon, booking ,tripadvisor…etc.
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          Orex analytics recomendación además pone en valor de los bibliotecarios y
          de su conocimiento de la colección, al permitir añadir al sistema de
          recomendación su experiencia , de manera que sus recomendaciones pueden
          llegar a todos y cada uno de sus usuarios a través del catálogo.
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          Orex analytics es una herramienta que incrementa la satisfacción de sus
          usuarios, incrementará el número de préstamos y le ayudará en su proceso de
          compra, al ser capaz de hacer recomendación de adquisiciones analizando su
          colección y comparándola con la de otras bibliotecas similares.
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" sx={{ pb: 1 }}>
          Como suscribir
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          Orex analytics recomendación es un servicio web que se integra con
          cualquier catálogo web, si desea más información póngase en contacto con
          nosotros enviando un email a info@orex.es o a través del formulario de
          contacto
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)
export default AboutPage
