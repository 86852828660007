/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react"
import { apiService } from "../config"

const getCurrentBook = async (biblio) => {
  const baseUrl = `/biblio`
  const {
    data: { book },
  } = await apiService.get(`${baseUrl}?biblionumber=${biblio}`)
  return book
}

const getBooks = async (editing, prefix) => {
  const mappedBiblios = editing.biblios.includes(",")
    ? editing.biblios.split(",").map((biblio) => `${prefix}_${biblio.trim()}`)
    : `${prefix}_${editing.biblios.trim()}`
  return getCurrentBook(mappedBiblios)
}

const UseGetRecomendedBooks = ({ editing, prefix }) => {
  const [bookList, setBoookList] = useState([])

  useEffect(() => {
    setBoookList([])
  }, [])

  useEffect(async () => {
    const result = await getBooks(editing, prefix)
    setBoookList(result && result.length ? result : [result])
  }, [editing])

  return bookList
}

export default UseGetRecomendedBooks
