/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { TextField, Button, Chip, Alert } from "@mui/material"

import Downshift from "downshift"

import { setLanguage } from "i18n"
import { apiService } from "config"

import { AlertMessage, ButtonFlat } from "components"
const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}))
const baseUrl = `/user-recomendations`

const createRecomendation = async ({
  isbn,
  title,
  biblio,
  library,
  libraryId,
  bookId,
  authorId,
  biblios,
}) => {
  const response = await apiService.post(`${baseUrl}`, {
    isbn,
    title,
    biblio,
    library,
    libraryId,
    bookId,
    authorId,
    biblios: biblios.toString(),
  })
  const {
    data: { list, books },
  } = response
  return { list, books }
}

const getCurrentBook = async (biblio) => {
  const baseUrlBiblio = `/biblio`
  const {
    data: { book },
  } = await apiService.get(`${baseUrlBiblio}?biblionumber=${biblio}`)
  return book
}

const defaultEditMode = {
  id: null,
  biblios: null,
  biblio: null,
  quickEdit: true,
}

const Add = ({
  book,
  user,
  library,
  language,
  tags,
  recomendationId,
  setEditing,
  setRecomendations,
  recomendations,
  books,
  mode,
  getRecomendations,
}) => {
  const translations = setLanguage(language)
  const [status, setStatus] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const [selectedItem, setSelectedItem] = useState([])
  const [formError, setFormError] = useState(null)
  const { prefix } = library
  const classes = useStyles()

  useEffect(() => {
    setSelectedItem(tags)
  }, [])

  const handleOnSubmit = async (evt) => {
    evt.preventDefault()

    const search = window.location.search

    const urlSearchParams = new URLSearchParams(search)

    const _book = await getCurrentBook(
      `${library.prefix}_${urlSearchParams.get("biblionumber")}`
    )
    if (!_book) return
    setStatus(null)

    const params = {
      isbn: _book._source.isbn,
      title: _book._source.title,
      biblio: _book._source.biblionumber,
      library: library.prefix,
      libraryId: library.id,
      bookId: user.id,
      authorId: user.id,
      biblios: selectedItem,
    }
    const { list, books } = await createRecomendation(params)
    if (!list && !books) {
      setStatus("error")

      return {}
    }

    if (list) {
      setStatus("success")

      return {}
    }

    setRecomendations({
      books,
      recomendations: [...(recomendations || []), books],
    })
    setStatus("success")

    return books
  }

  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      const newSelectedItem = [...selectedItem]
      const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim())

      if (duplicatedValues !== -1) {
        setInputValue("")
        return
      }
      if (!event.target.value.replace(/\s/g, "").length) return

      newSelectedItem.push(event.target.value.trim())

      const checkBookExistance = await getCurrentBook(
        `${prefix}_${event.target.value.trim()}`
      )
      setFormError(true)

      if (checkBookExistance) {
        setFormError(null)
        setInputValue("")
        setSelectedItem(newSelectedItem)
      }
    }
    if (selectedItem.length && !inputValue.length && event.key === "Backspace") {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue("")
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  function handleInputChange(event) {
    setInputValue(event.target.value)
  }

  return (
    <div>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder: translations["add_biblios"],
          })
          return (
            <div>
              <TextField
                error={selectedItem.length < 1 || formError}
                InputProps={{
                  startAdornment: selectedItem.map((item) => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus,
                  sx: { flexWrap: "wrap" },
                }}
                fullWidth
                variant="standard"
                id="tags"
                name="tags"
                label={translations["add_biblios"]}
                {...inputProps}
                sx={{ pb: 2, flexWrap: "wrap" }}
                type="number"
              />
            </div>
          )
        }}
      </Downshift>
      {mode === "edit" && (
        <>
          <ButtonFlat
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            onClick={async () => {
              const { data } = await apiService.put(`${baseUrl}`, {
                biblios: selectedItem.toString(),
                id: recomendationId,
              })
              setEditing(defaultEditMode)
            }}
          >
            {translations["save"]}
          </ButtonFlat>
          <ButtonFlat
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            onClick={() => setEditing(defaultEditMode)}
          >
            {translations["cancel"]}
          </ButtonFlat>
          <ButtonFlat
            sx={{ mr: 2 }}
            size="small"
            disabled
            variant="outlined"
            onClick={async () => {
              const mappedBiblios = selectedItem.map(
                (biblio) => `${library.prefix}_${biblio.trim()}`
              )
              const bookList = await getCurrentBook(mappedBiblios)

              return bookList
            }}
          >
            {translations["validate"]}
          </ButtonFlat>
        </>
      )}
      {mode === "create" && (
        <ButtonFlat
          disabled={selectedItem.length < 1}
          onClick={async (evt) => {
            const params = await handleOnSubmit(evt)
            const setRecomendations = await getRecomendations(user)
          }}
        >
          {translations["create"]}
        </ButtonFlat>
      )}
      <AlertMessage severity={status}>
        {translations["error_recomendation"]}
      </AlertMessage>
    </div>
  )
}
export default Add
