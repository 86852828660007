/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { Dialog, DialogContent } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import Header from "./components/Header"

const BookDialog = ({ children, setShow, library, language, book }) => {
  const matches = useMediaQuery("(max-width:600px)")
  const [container, setContainer] = useState(null)

  useEffect(() => {
    const div = document.createElement("div")
    const newContainer = document
      .querySelector(library.placement)
      .querySelectorAll("#books-widget")[0]
      .shadowRoot.appendChild(div)

    setContainer(newContainer)
  }, [children])

  return !container ? (
    <div />
  ) : (
    <Dialog
      className="booksModal"
      container={container}
      fullWidth
      fullScreen={matches}
      maxWidth={"xl"}
      open={true}
      onClose={() => setShow(false)}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      style={{ overflowY: "hidden !important" }}
    >
      <Header library={library} language={language} book={book} setShow={setShow} />
      <DialogContent className="content" sx={{ minHeight: "80vh", p: { xs: 0 } }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default BookDialog
