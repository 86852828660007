/* eslint-disable no-unused-vars */
import * as React from "react"
import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button"
import { grey } from "@mui/material/colors"

const ColorButton = styled(Button)(({ theme, disabled }) => ({
  [theme.breakpoints.up("md")]: {
    width: "auto",

    backgroundColor: disabled ? grey[200] : grey[900],
  },

  color: grey[100],
  fontSize: "12px !important",
  backgroundColor: grey[900],
  padding: "4px 12px",
  borderRadius: 0,
  marginBottom: "1rem",
  width: "100%",

  "&:hover": {
    backgroundColor: grey[700],
  },
}))

export default function ButtonFlat(props) {
  return <ColorButton {...props} variant="standard" />
}
