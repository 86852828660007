import { atom } from "recoil"

const userAtom = atom({
  key: "userAtom",
  default: {
    username: null,
    id: null,
    admin: false,
    language: "es-EN",
    guid: null,
  },
})

export default userAtom
