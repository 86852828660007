/* eslint-disable prettier/prettier */
export const ROWS_PER_PAGE = 20
export const DEFAULT_ORDER = "desc"

export const HEADER_CELLS = [
  {
    id: "cover",
    numeric: false,
    disablePadding: true,
    label: "cover",
  },
  {
    id: "score",
    numeric: true,
    disablePadding: true,
    label: "score",
  },
  {
    id: "country",
    numeric: true,
    disablePadding: false,
    label: "country",
  },
  {
    id: "library",
    numeric: false,
    disablePadding: false,
    label: "library",
  },
  {
    id: "title",
    numeric: true,
    disablePadding: false,
    label: "title",
  },
  {
    id: "author",
    numeric: true,
    disablePadding: false,
    label: "author",
  },
  {
    id: "isbn",
    numeric: false,
    disablePadding: false,
    label: "ISBN",
  },
  {
    id: "external",
    numeric: false,
    disablePadding: false,
    label: "external",
  },
]

export const ROWS_PAGINATION = [25, 50, 75, 100]
