/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { FormContainer, TextFieldElement } from "react-hook-form-mui"
import { Button, Alert, NativeSelect, FormControl, Typography } from "@mui/material"

import { apiService } from "config"
import { setLanguage } from "i18n"

import { useGetAllLibraries, useStoredSession } from "hooks"
import { ButtonFlat } from "components"

const UpdateLibraryForm = ({ libraryId }) => {
  const [user] = useStoredSession()
  const [libraries, setNewLibraries] = useGetAllLibraries()

  const [status, setStatus] = useState(false)
  const translations = setLanguage(user.language)

  const handleUpdateLibrary = async (data) => {
    const baseUrl = `/library`
    const results = await apiService
      .put(`${baseUrl}`, { id: libraryId, ...data })
      .catch((error) => setStatus("error"))
    if (results) {
      return setNewLibraries([...libraries, results.data])
    }
  }

  return (
    <>
      <Typography sx={{ paddingBottom: "1em" }} fontWeight={900} variant="h6">
        {translations["update_configuration"]}
      </Typography>

      <FormContainer
        defaultValues={{
          placement: "#app, #menu, #ulactioncontainer",
          resultsFrom: "global",
          useBlacklist: "global",
        }}
        onSuccess={(data) => {
          handleUpdateLibrary(data)
        }}
      >
        <TextFieldElement
          helperText="Placement Selectors."
          id="placement"
          name="placement"
          required
          sx={{ width: "100%", pb: 2 }}
          label={"Placement"}
          variant="standard"
          type={"text"}
        />

        <FormControl sx={{ pb: 2 }} fullWidth>
          <Typography> {translations["blacklist"]}</Typography>
          <NativeSelect
            required
            name="useBlacklist"
            label="Blacklist"

            // onChange={({ target }) =>
            //   setSelectValue({
            //     ...selectValue,
            //     type: target.value,
            //   })
            // }
          >
            <option key={`global`} value={`GLOBAL`}>
              {`Global`}
            </option>
            <option key={`local`} value={`LOCAL`}>
              {`Local`}
            </option>
            <option key={`optional`} value={`OPIONAL`}>
              {`Optional`}
            </option>
          </NativeSelect>
        </FormControl>

        <FormControl sx={{ pb: 2 }} fullWidth>
          <Typography>{translations["source_recomendations"]}</Typography>
          <NativeSelect
            required
            name="resultsFrom"
            label="Results"
            // onChange={({ target }) =>
            //   setSelectValue({
            //     ...selectValue,
            //     type: target.value,
            //   })
            // }
          >
            <option key={`global`} value={`GLOBAL`}>
              {`Global`}
            </option>
            <option key={`local`} value={`LOCAL`}>
              {`Local`}
            </option>
          </NativeSelect>
        </FormControl>

        <ButtonFlat disableElevation type="submit" size="small">
          {["update_library"]}
        </ButtonFlat>
        {status && <Alert severity={status}>{translations["error_library"]}</Alert>}
      </FormContainer>
    </>
  )
}
export default UpdateLibraryForm
