import React, { useEffect, useState } from "react"
import { Grid, Typography, DialogTitle } from "@mui/material"
import { Close, AccountCircle } from "@mui/icons-material"

import { useStoredSession } from "hooks"
import { setLanguage } from "i18n"

import { clean } from "utils"
import { DropMenu } from "components"

import { apiService } from "config"

const getCurrentBook = async (biblio) => {
  const baseUrl = `/biblio`
  const {
    data: { book },
  } = await apiService.get(`${baseUrl}?biblionumber=${biblio}`)
  return book
}

const Header = ({ library, language, setShow }) => {
  const search = window.location.search
  const urlSearchParams = new URLSearchParams(search)

  const [book, setBook] = useState(null)
  const biblios = `${library.prefix}_${urlSearchParams.get("biblionumber")}`

  useEffect(() => {
    ;(async function () {
      if (!biblios.includes(",") && !biblios.includes("_null")) {
        let bookDetail = await getCurrentBook(biblios).catch(() => {
          return false
        })
        setBook(bookDetail)
      }
    })()
  }, [search])
  const translations = setLanguage(language)

  const [user] = useStoredSession()

  return (
    <DialogTitle
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "#FFF",
        zIndex: 9999,
        borderBottom: "1px solid #ccc",
        boxShadow: 1,
      }}
    >
      <Grid
        sx={{ px: 0, pr: 0 }}
        justifyContent="space-between"
        alignItems="flex-start"
        container
        spacing={2}
      >
        <Grid item md={4}>
          <Typography
            sx={{
              paddingTop: {
                xs: "2rem",
                md: "0",
              },
            }}
            variant="subtitle2"
          >
            {translations["headline"]}
          </Typography>
          <Typography noWrap sx={{ fontWeight: 900, lineHeight: 1 }} variant="h6">
            {library.label}
          </Typography>
        </Grid>

        <Grid
          item
          md={7}
          justifyContent="space-between"
          alignItems="flex-start"
          container
          spacing={2}
        >
          <Grid item md={6} style={{ textAlign: "left" }}>
            <Typography noWrap sx={{ fontWeight: 900 }} variant="subtitle2">
              {book && book._source.author}
            </Typography>
            <Typography noWrap variant="h6" sx={{ lineHeight: 1 }}>
              {clean(book && book._source.title)}
            </Typography>

            <Typography noWrap variant="subtitle1">
              {book && book._source.editorial}
            </Typography>
          </Grid>
          <Grid
            sx={{
              width: {
                xs: "80%",
              },
              top: {
                xs: "0px",
                md: "auto",
              },
              left: {
                xs: "15px",
                md: "auto",
              },
              position: {
                xs: "absolute",
                md: "relative",
              },
              order: {
                xs: -1,
                md: 1,
              },
            }}
            item
            md={2}
          >
            <Grid container justifyContent="flex-end" alignItems="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                {user && user.username && (
                  <>
                    <AccountCircle sx={{ mr: 1 }} />
                    <Typography variant="subtitle2">{user.username}</Typography>
                  </>
                )}
                <DropMenu language={language} placement={library.placement} />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={1}
          sx={{
            right: {
              xs: "15px",
              md: "auto",
            },
            position: {
              xs: "absolute",
              md: "relative",
            },
            order: {
              xs: -1,
              md: 1,
            },
          }}
          style={{ textAlign: "right", maxWidth: "40px" }}
        >
          <Close
            fontSize="medium"
            style={{ cursor: "pointer" }}
            onClick={() => setShow(false)}
          />
        </Grid>
      </Grid>
    </DialogTitle>
  )
}

export default Header
