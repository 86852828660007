/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { Grid, Typography, Divider, Box } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Masonry } from "@mui/lab"
import { styled } from "@mui/material/styles"

import { apiService } from "config"
import { setLanguage } from "i18n"
import { clean } from "utils"

import { Spinner, BackButton, ButtonFlat } from "components"

import Books from "../Books"
import Add from "../Add"

const sorter = (a, b) => {
  const date1 = new Date(b.createdAt)
  const date2 = new Date(a.createdAt)
  if (date1 < date2) return -1
  else if (date1 === date2) return 1
  else return 0
}

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  border: "none",
  display: "flex",
  flexDirection: "column",
}))

const BookAvatar = ({
  prefix,
  biblio,
  books,
  createdAt,
  recomendations,
  language,
}) => {
  const translations = setLanguage(language)

  const displayBook =
    books.book.length > 1
      ? books.book.find((book) => book._source.biblionumber === biblio)
      : books.book
  return (
    <>
      <Divider sx={{ my: "1rem" }} />

      <Grid container style={{ width: "100%" }}>
        <Typography sx={{ fontWeight: "900" }} variant="subtitle2">
          {translations["added"]}:
        </Typography>

        <Typography variant="subtitle2">
          {" "}
          {new Date(createdAt).toDateString()}
        </Typography>
      </Grid>

      {displayBook && (
        <div>
          <Typography sx={{ fontWeight: "900" }} variant="h5">
            <a
              href={`//${prefix}.orex.es/cgi-bin/koha/opac-detail.pl?biblionumber=${displayBook._source.biblionumber}`}
              target={"_blank"}
              rel="noreferrer"
            >
              {clean(displayBook._source.title)}
            </a>
          </Typography>
          <Typography variant="h6">{displayBook._source.author}</Typography>
          <Typography
            sx={{ mb: "1rem" }}
            variant="subtitle2"
          >{`${translations["this_book_has"]} ${recomendations} ${translations["recomendations"]}`}</Typography>
        </div>
      )}
    </>
  )
}

const defaultEditMode = {
  id: null,
  biblios: null,
  biblio: null,
  quickEdit: true,
}

const getCurrentBook = async (biblio) => {
  const baseUrlBiblio = `/biblio`
  const {
    data: { book },
  } = await apiService.get(`${baseUrlBiblio}?biblionumber=${biblio}`)
  return book
}

const List = ({ book, user, language, library }) => {
  const matches = useMediaQuery("(max-width:600px)")

  const translations = setLanguage(language)
  const [{ recomendations, books }, setRecomendations] = useState({
    recomendations: null,
    books: null,
  })
  const [editing, setEditing] = useState(defaultEditMode)
  const [editingObject, setEditingObject] = useState(null)
  const [__book, _setBook] = useState({})
  useEffect(async () => {
    if (user.id) {
      await getRecomendations(user)

      const search = window.location.search
      // if (search) {
      // }
      const urlSearchParams = new URLSearchParams(search)

      const _book = await getCurrentBook(
        `${library.prefix}_${urlSearchParams.get("biblionumber")}`
      )
      _setBook(_book)
    }
  }, [])

  const deleteRecomendation = async (id) => {
    const { data } = await apiService.delete(`/user-recomendations/${id}`)
    setRecomendations({
      recomendations: recomendations.filter(
        (recomendation) => recomendation.id !== id
      ),
      books,
    })
    return data
  }

  const getRecomendations = async (user) => {
    const { data } = await apiService.get(`/user-recomendations/${user.id}`)
    setRecomendations(data)
  }

  if (!__book?._source) return null

  const isIncluded = recomendations
    ? recomendations.find(
        (_book) => __book._source.isbn && __book._source.isbn.includes(_book.isbn)
      )
    : {}

  return (
    <>
      {recomendations ? (
        <Grid item style={{ width: "100%" }} sx={{ p: 2 }}>
          {/* Component Recomended Books */}
          {editing.id && !editing.quickEdit && (
            <>
              <Grid container style={{ width: "100%" }}>
                <Grid item xs={12} md={2}>
                  <BackButton
                    handleOnClick={async () => {
                      setEditing(defaultEditMode)

                      return await getRecomendations(user)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" sx={{ fontWeight: "900" }}>
                    {translations["for_recomendations"]}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography variant="h6" sx={{ fontWeight: "200" }}>
                    {clean(__book._source.title)}
                  </Typography>
                </Grid>
              </Grid>
              <Books editing={editing} />
            </>
          )}

          {/* Component Recomended Books Add */}
          {!isIncluded && (
            <Add
              getRecomendations={getRecomendations}
              mode={"create"}
              book={__book}
              tags={[]}
              user={user}
              library={library}
              language={language}
            />
          )}

          {/* Component Recomended Books Edit */}
          <Grid container md={12} sx={{ p: 2 }}>
            {(!editing.id || editing.quickEdit) && (
              <>
                <Grid container style={{ width: "100%" }}>
                  {/* <Grid item xs={12} md={2}>
                    <BackButton
                      handleOnClick={async () => {
                        setEditing(defaultEditMode)

                        return await getRecomendations(user)
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={3}>
                    <Typography fontWeight={900} variant="h6">
                      {translations["all_recomendations"]}{" "}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} md={7}>
                    <Typography fontWeight={200}>{user.username}</Typography>
                  </Grid> */}
                </Grid>
                <Masonry
                  columns={matches ? 1 : 3}
                  spacing={matches ? 0 : 4}
                  defaultHeight={450}
                >
                  {recomendations
                    .sort((a, b) => sorter(a, b))
                    .map((item, index) => {
                      const { id, biblios, createdAt, biblio } = item
                      const numberOfRecomendations = biblios.split(",").length || 0
                      return id ? (
                        <Item key={index}>
                          {/* Component List not editing  */}
                          {(!editing.id || editing.id !== id) && editing.quickEdit && (
                            <>
                              <BookAvatar
                                createdAt={createdAt}
                                recomendations={numberOfRecomendations}
                                biblio={biblio}
                                books={books}
                                prefix={item.library}
                                language={language}
                              />
                              <Grid item style={{ width: "100%" }}>
                                <ButtonFlat
                                  sx={{ mr: 1 }}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    setEditing({
                                      id,
                                      quickEdit: true,
                                    })
                                  }
                                >
                                  {translations["quick_edit"]}
                                </ButtonFlat>
                                <ButtonFlat
                                  sx={{ mr: 1 }}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    setEditing({
                                      id,
                                      biblios,
                                      biblio,
                                      prefix: item.library,
                                      quickEdit: false,
                                    })
                                  }
                                >
                                  {translations["check_recomendations"]}
                                </ButtonFlat>
                                <ButtonFlat
                                  onClick={async () => {
                                    if (confirm("delete")) {
                                      deleteRecomendation(id)
                                    }
                                  }}
                                  sx={{ mr: 1 }}
                                  size="small"
                                  variant="outlined"
                                >
                                  {translations["delete"]}
                                </ButtonFlat>
                              </Grid>
                            </>
                          )}
                          {/* Component List editing  */}
                          {editing.id === id && editing.quickEdit && (
                            <div>
                              <BookAvatar
                                recomendations={numberOfRecomendations}
                                createdAt={createdAt}
                                biblio={biblio}
                                books={books}
                                language={language}
                              />

                              <Add
                                mode={"edit"}
                                getRecomendations={getRecomendations}
                                recomendations={recomendations}
                                books={books}
                                editing={editing}
                                editingObject={editingObject}
                                setEditingObject={setEditingObject}
                                setEditing={setEditing}
                                setRecomendations={setRecomendations}
                                recomendationId={id}
                                book={book}
                                tags={biblios.split(",")}
                                user={user}
                                library={library}
                                language={language}
                              />
                            </div>
                          )}
                        </Item>
                      ) : (
                        <div>{translations["no_results"]}</div>
                      )
                    })}
                </Masonry>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <div>
          <Spinner language={language} />
        </div>
      )}
    </>
  )
}

export default List
