import React from "react"
import { Grid } from "@mui/material"

import BookGrid from "features/BookGrid"
import BookFilters from "features/BookFilters"

const GridPage = ({
  sidebar,
  value,
  books,
  language,
  selected,
  setBooks,
  setPosition,
  setSelected,
  library,
  filters,
  setValue,
  bookList,
}) => {
  return (
    <>
      <Grid
        boxShadow={{ xs: 2, md: 0 }}
        sx={{
          p: 2,
          position: "sticky",
          top: 0,
          backgroundColor: "#FFF",
          zIndex: "1",
        }}
        item
        xs={12}
        md={3}
        className={` ${sidebar ? "active" : "closed"}`}
      >
        <BookFilters
          resultsFrom={library.resultsFrom}
          label={library.label}
          setValue={setValue}
          setBooks={setBooks}
          bookList={bookList}
          filters={filters}
          value={value}
        />
      </Grid>
      <Grid sx={{ p: 2 }} item xs={12} md={9}>
        <BookGrid
          value={value}
          books={books}
          filters={filters}
          language={language}
          library={library}
          selected={selected}
          setPosition={setPosition}
          setSelected={setSelected}
        />
      </Grid>
    </>
  )
}

export default GridPage
