const clean = (string) => string && string.replace(/[/\\;:]/g, "")
/* Options
----------------------------------------------------------------------------------
string && string.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "")
string.replace(/[^\w\s]|_/g, "") 
*/
const getShortYear = (date) => {
  try {
    return `${new Date(date.toString()).getUTCFullYear().toString()}`
  } catch (error) {
    return null
  }
}

const generateUUID = () => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16)
  })
}

export { clean, getShortYear, generateUUID }
