/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react"
import {
  Typography,
  Grid,
  List,
  ListItem,
  Fade,
  Divider,
  IconButton,
} from "@mui/material"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import useMediaQuery from "@mui/material/useMediaQuery"

import { clean } from "utils"

const fallbackImage =
  "https://orex.es/wp-content/uploads/2020/12/cropped-CSIC-DIGITAL09122014orex-logo.png"

const BookDetail = ({
  googleBookInfo,
  googleBookSearch,
  selected,
  position,
  size,
  goToPrevious,
  goToNext,
}) => {
  const [checked, setChecked] = useState(true)
  const matches = useMediaQuery("(min-width:600px)")
  const swipe = React.useRef(null)
  useEffect(() => {
    swipe.current.addEventListener("drag", function (evt) {
      evt.preventDefault()
      setChecked(false)

      setTimeout(() => {
        setChecked(true)
        goToNext()
      }, 0)
    })
  }, [])

  return (
    <Grid
      style={{ width: "100%" }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ p: 2 }}
    >
      <Grid item xs={1} md={1}>
        <IconButton
          disabled={position <= 0}
          aria-label="previous"
          color="inherit"
          onClick={() => {
            setChecked(false)

            setTimeout(() => {
              setChecked(true)
              goToPrevious()
            }, 0)
          }}
        >
          <ArrowBackIos size="small" />
        </IconButton>
      </Grid>
      <Grid item xs={10} md={10}>
        {/*  */}

        <Fade direction="right" in={checked}>
          <div ref={swipe}>
            <Grid
              style={{ width: "100%" }}
              container
              justifyContent="space-between"
              md={12}
              xs={12}
            >
              <Grid item xs={12} md={2} sx={{ px: 2 }}>
                <img
                  style={{ maxWidth: "150px", width: "100%" }}
                  src={
                    (googleBookInfo &&
                      googleBookInfo.volumeInfo.imageLinks &&
                      googleBookInfo.volumeInfo.imageLinks.thumbnail) ||
                    "//ro.dolfo.me/orex/no-results.svg"
                  }
                />
              </Grid>

              <Grid item xs={12} md={10} sx={{ px: 2 }}>
                <Typography variant="subtitle2">
                  Index {position} / {size}
                </Typography>
                {/* COMPONENT */}
                <Divider sx={{ borderBottomWidth: 4 }} />
                <Grid
                  style={{ width: "100%", padding: "1rem 0" }}
                  container
                  justifyContent="space-between"
                  md={12}
                  xs={12}
                >
                  {selected.date && (
                    <Grid item xs={12} md={2}>
                      <Typography fontWeight={900} variant="h4">
                        {selected.date}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} md={10}>
                    <Typography
                      variant="h4"
                      fontWeight={900}
                      sx={{ margin: 0, lineHeight: "1" }}
                    >
                      {selected.title && clean(selected.title)}
                    </Typography>
                  </Grid>
                </Grid>
                {googleBookInfo && googleBookInfo.volumeInfo.subtitle && (
                  <Divider sx={{ borderBottomWidth: 4 }} />
                )}
                {googleBookInfo && googleBookInfo.volumeInfo.subtitle && (
                  <Typography variant="h6">
                    {googleBookInfo.volumeInfo.subtitle}
                  </Typography>
                )}
                {/* COMPONENT */}
                <Divider sx={{ borderBottomWidth: 2 }} />
                <Grid container>
                  <Grid item xs={12} md={8} sx={{ py: 2 }}>
                    <Typography fontWeight={900} variant="h6">
                      {selected.author}
                    </Typography>
                    <Typography
                      size="small"
                      sx={{ mt: 2 }}
                      style={{
                        width: matches ? "400px" : "100%",
                      }}
                    >
                      {(googleBookInfo || googleBookSearch) &&
                        ((googleBookInfo.volumeInfo &&
                          googleBookInfo.volumeInfo.description) ||
                          (googleBookInfo.searchInfo &&
                            googleBookInfo.searchInfo.textSnippet) ||
                          "")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ py: 2 }}>
                    <Typography fontWeight={900}>{"Publisher"}</Typography>
                    <Typography>{selected.publisher}</Typography>

                    <Typography fontWeight={900}>{"Subject"}</Typography>

                    <Typography>
                      {selected.subject && (
                        <List>
                          {selected.subject.map((subject, index) => (
                            <ListItem key={`${subject}-${index}`}>
                              {subject}
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>

        {/*  */}
      </Grid>
      <Grid item xs={1} md={1} style={{ textAlign: "right" }}>
        <IconButton
          disabled={size <= position}
          aria-label="previous"
          color="inherit"
          onClick={() => {
            setChecked(false)

            setTimeout(() => {
              setChecked(true)
              goToNext()
            }, 0)
          }}
        >
          <ArrowForwardIos size={"small"} />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default BookDetail
