import React, { useEffect, useState } from "react"
import { Grid, Typography, Button } from "@mui/material"
import { Delete, PersonAdd } from "@mui/icons-material"

import { useGetAllUsers, useGetAllLibraries, useStoredSession } from "hooks"

import Table from "./Table"
import AddUserForm from "./AddUserForm"
import LibraryDropDown from "./LibraryDropDown"

import { apiService } from "config"
import { setLanguage } from "i18n"

const User = () => {
  const [user] = useStoredSession()
  const [users, setNewUsers] = useGetAllUsers()
  const [libraries] = useGetAllLibraries()

  const [localUsers, setLocalUsers] = useState([])
  const [mode, setMode] = useState("table")

  const translations = setLanguage(user.language)

  const usersTableConfig = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "email",
      headerName: "e-mail",
      primary: false,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      primary: false,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "id",
      headerName: "Delete",
      primary: false,
      renderCell: (row) => {
        return (
          <div>
            <Button
              onClick={() => {
                return handleDeleteUser(row.id)
              }}
            >
              <Delete />
            </Button>
          </div>
        )
      },
    },
  ]

  const filteredUsers = ({ libraryId }) => {
    const id = +libraryId
    return id
      ? setLocalUsers(users.filter((user) => user.libraryId === id))
      : setLocalUsers(users)
  }

  const handleDeleteUser = async (id) => {
    const baseUrl = `/user/${id}`
    const results = await apiService.delete(`${baseUrl}`)
    if (results) {
      const newUsersList = users.filter((user) => user.id !== id)
      setLocalUsers(newUsersList)
      return setNewUsers(newUsersList)
    }
  }

  useEffect(() => {
    setLocalUsers(users)
  }, [users])

  return (
    <Grid container sx={{ p: 0 }} style={{ width: "100%" }}>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        item
        style={{ width: "100%" }}
      >
        <Grid
          justifyContent="space-between"
          alignItems="flex-start"
          container
          style={{ width: "100%" }}
        >
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "900" }}>
              {mode === "table"
                ? translations["all_users"]
                : translations["create_users"]}
            </Typography>
          </Grid>
          {user.type === "ADMIN" && mode === "table" && (
            <Grid item>
              <LibraryDropDown
                user={user}
                selectValue={user.libraryId}
                setSelectValue={(value) => filteredUsers(value)}
                libraries={libraries}
                showDefault
              />
            </Grid>
          )}
          <Grid item>
            {mode === "table" && (
              <Button disableElevation onClick={() => setMode("form")}>
                <PersonAdd sx={{ mr: 1 }} /> {translations["create_users"]}
              </Button>
            )}
            {mode === "form" && (
              <Button disableElevation onClick={() => setMode("table")}>
                {translations["cancel"]}
              </Button>
            )}
          </Grid>
        </Grid>
        {/* Table */}
        {mode === "table" && <Table rows={localUsers} columns={usersTableConfig} />}
        {mode === "form" && (
          <AddUserForm
            handlesetLocalUsers={setLocalUsers}
            handleSetMode={setMode}
            libraries={libraries}
          />
        )}
      </Grid>
    </Grid>
  )
}
export default User
