/* eslint-disable no-unused-vars */
import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { userAtom } from "../atoms"
import useDropDownMenu from "./useDropDownMenu"

function getLocalStoredUser(key) {
  try {
    const item = localStorage.getItem(key)
    if (!item) throw new Error()
    return JSON.parse(item)
  } catch (err) {
    return null
  }
}

export const useStoredSession = () => {
  const key = "orex-session"
  const [user, setStoredUser] = useRecoilState(userAtom)
  const [, setPage] = useDropDownMenu("grid")

  const sessionHasExpired = (e) => {
    setStoredUser({ username: null })
    setPage("login")
  }

  useEffect(() => {
    document.addEventListener("session-expired", sessionHasExpired)
    return () => document.removeEventListener("session-expired", sessionHasExpired)
  }, [])

  const setUser = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(user) : value
      setStoredUser(valueToStore)

      if (valueToStore.username) {
        localStorage.setItem(key, JSON.stringify(valueToStore))
        setPage("grid")
      } else {
        localStorage.removeItem(key)
        setPage("login")
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (!user.username)
    getLocalStoredUser(key) ? setStoredUser(getLocalStoredUser(key)) : null

  return [user, setUser]
}

export default useStoredSession
