import { atom } from "recoil"

const userListAtom = atom({
  key: "userListAtom",
  default: [
    {
      email: null,
      id: 0,
      libraryId: null,
      name: null,
      type: null,
    },
  ],
})

export default userListAtom
