import React from "react"
import { FormControlLabel, Switch, Typography } from "@mui/material"

const SelectFilter = ({ title, value, handleOptions, id, resultsFrom }) => {
  const internal = id === "internal" && resultsFrom
  return (
    <FormControlLabel
      size="small"
      control={
        <Switch
          disabled={internal}
          id={id}
          checked={internal ? true : value.checkbox}
          size="small"
        />
      }
      label={
        <Typography
          sx={{ lineHeight: "1", fontSize: "10px" }}
        >{`${title}`}</Typography>
      }
      onChange={({ target }) => {
        handleOptions(target.checked, id)
      }}
    />
  )
}

export default SelectFilter
