import { useEffect } from "react"
import { useRecoilState } from "recoil"

import { allRecomendations } from "atoms"
import { apiService } from "config"

const useGetAllRecomendations = () => {
  const [recomendations, setRecomendations] = useRecoilState(allRecomendations)
  useEffect(async () => {
    const baseUrl = `/all-recomendations`
    const results = await apiService.get(`${baseUrl}`)
    setRecomendations(results.data)
    // return results.data
  }, [])
  return [recomendations]
}

export default useGetAllRecomendations
