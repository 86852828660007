import React from "react"
import { CircularProgress, Box } from "@mui/material"
import { setLanguage } from "../../i18n"

const Spinner = ({ language }) => {
  const tranlations = setLanguage(language)

  return (
    <Box align="center" justify="between" direction="row">
      <div
        style={{
          paddingBottom: "1rem",
          fontWeight: 400,
          fontSize: "12px",
          color: "#FFF",
        }}
      >
        {" "}
        <hr />
        {`${tranlations["loading"]}`}
      </div>
      <CircularProgress color="secondary" />
    </Box>
  )
}
export default Spinner
