/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Avatar } from "@mui/material"
import { KeyboardBackspace } from "@mui/icons-material"
const BackButton = ({ handleOnClick }) => {
  const [hover, setHover] = useState(0)

  return (
    <Avatar
      sx={{
        width: "50px",
        height: "50px",
        border: `1px solid ${"#000"}`,
        bgcolor: "transparent",
        opacity: hover ? 0.5 : 1,
      }}
      onClick={() => typeof handleOnClick === "function" && handleOnClick()}
      onMouseEnter={() => {
        setHover(1)
      }}
      onMouseLeave={() => {
        setHover(0)
      }}
    >
      <KeyboardBackspace
        style={{ cursor: "pointer" }}
        sx={{
          color: "black",
          fontSize: "2rem",
          fontStyle: "100",
        }}
      >
        {/* <Typography variant="overline">Volver</Typography> */}
      </KeyboardBackspace>
    </Avatar>
  )
}
export default BackButton
