import esES from "./es-ES"
import caES from "./es-ES"
import en from "./es-ES"

const setLanguage = (language) => {
  switch (language) {
    case "es-ES":
      return esES
    case "ca-ES":
      return caES
    default:
      return en
  }
}

export { setLanguage }
