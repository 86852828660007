/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react"
import { useRecoilState } from "recoil"

import { Box, Avatar, Typography, IconButton, Link } from "@mui/material"

import Collapse from "@mui/material/Collapse"
import TablePagination from "@mui/material/TablePagination"

// import Grid from "@mui/material/Grid"
// import Table from "@mui/material/Table"
// import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
// import TableSortLabel from "@mui/material/TableSortLabel"
// import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { PlaylistRemove, PlaylistAdd, Flag } from "@mui/icons-material"

// import { WorkspacePremium, LocalLibrary, Public } from "@mui/icons-material"

// import ProgressBar from "components/ProgressBar"
// import { setLanguage } from "i18n"
// import { clean } from "utils"
// import { BookAvatarMemo } from "components/BookAvatar/BookAvatar"

// import { visuallyHidden } from "@mui/utils"

import { descendingComparator, stableSort, getComparator } from "./BookGrid.Utils"
import {
  // HEADER_CELLS,
  ROWS_PER_PAGE,
  // DEFAULT_ORDER,
  ROWS_PAGINATION,
} from "./BookGrid.Constants"

import {
  // TooltipPatch,
  BookAvatarItem,
  BookLinkItem,
  CanonicalLinkItem,
  EnhancedTableHeader,
  ScoreBarItem,
  FlagItem,
  CollapsedBookList,
  Legend,
} from "./components"

// TODO: remove
// Add translations

const BookGrid = ({
  books,
  setSelected,
  setPosition,
  language,
  library,
  filters,
  value,
}) => {
  const [open, setOpen] = React.useState({})
  const [order, setOrder] = React.useState("desc")
  const [selectedd] = React.useState([])

  const [orderBy, setOrderBy] = React.useState("score")

  const [rowsPerPage, setRowsPerPage] = React.useState(ROWS_PER_PAGE)
  const [page, setPage] = React.useState(0)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const select = (row) => {
    const index = books.findIndex((book) => book.isbn === row.isbn)
    setSelected(row)
    setPosition(index)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, ROWS_PER_PAGE))
    setPage(0)
  }
  return (
    <div className="grid" style={{ overflow: "auto" }}>
      <EnhancedTableHeader
        language={language}
        numSelected={selectedd.length}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={books.length}
      />
      {books.length ? (
        stableSort(books, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((book) => (
            <>
              <TableRow sx={{ "& > *": { borderBottom: "1px solid red" } }}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ verticalAlign: "top" }}
                >
                  <BookAvatarItem
                    book={book}
                    select={select}
                    language={language}
                    modal
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <ScoreBarItem book={book} />
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <FlagItem countries={filters.countries} countryId={book.country} />
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <Typography
                    sx={{ lineHeight: 1, fontSize: "14px" }}
                    fontWeight={600}
                  >
                    {book.library}
                  </Typography>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <BookLinkItem book={book} />
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <Typography variant="caption">{book.author}</Typography>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <Typography
                    variant="caption"
                    sx={{ textOverflow: "ellipsis", maxWidth: "100px" }}
                  >
                    {book.isbn}
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{ verticalAlign: "top" }}>
                  <CanonicalLinkItem book={book} />
                </TableCell>
                <TableCell sx={{ p: 0 }}>
                  {!value.internal && book.duplicates && (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setOpen((state) => {
                          const current = state[book.id]
                          return { [book.id]: !current }
                        })
                      }
                    >
                      {open[book.id] ? <PlaylistRemove /> : <PlaylistAdd />}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
              {/*  */}
              {!value.internal && book.duplicates && (
                <TableRow
                  sx={{ "& > *": { borderBottom: "1px solid transparent" } }}
                >
                  <TableCell style={{ paddingBottom: 4, paddingTop: 4 }} colSpan={7}>
                    <Collapse in={open[book.id]} timeout="auto" unmountOnExit>
                      <Box sx={{ p: 2 }}>
                        <CollapsedBookList
                          duplicates={book.duplicates}
                          library={library}
                          filters={filters}
                          language={language}
                        />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </>
          ))
      ) : (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell colSpan={7} sx={{ p: 2 }}>
            <Typography>Sin Resultados</Typography>
          </TableCell>
        </TableRow>
      )}

      <Legend library={library} />

      <TablePagination
        SelectProps={{ native: true }}
        rowsPerPageOptions={ROWS_PAGINATION}
        component="div"
        count={books.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

// TODO: Book Grid gets initial data
export default React.memo(BookGrid)
