import axios from "axios"
export const API_URL = "https://api-elastic7.orex.es/v1"
// http://localhost:3001/v1
// https://api-elastic7.orex.es/v1

var username = "orex"
var password = "$2a$10$iqJSHD"

var basicAuth = "Basic " + btoa(username + ":" + password)

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    Authorization: basicAuth,
  },
})

const instance = axiosInstance
export { instance }

const getStoredSession = (_response) => {
  localStorage.removeItem("orex-session")

  const sessionExpired = new CustomEvent("session-expired", {
    detail: {
      name: "session-expired",
    },
  })
  document.dispatchEvent(sessionExpired)

  return _response
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const _response = error.response || {} || error
    if (_response.status >= 400 && _response.status < 500) {
      return getStoredSession(_response)
    }
    // Patch to provie feedback
    throw new Error(_response)
  }
)

export const apiService = {
  get(url, params) {
    return axiosInstance.get(url, { params })
  },

  post(url, data, headers = { "content-type": "application/json" }) {
    return axiosInstance.post(url, data, { headers })
  },

  put(url, data, headers = { "content-type": "application/json" }) {
    return axiosInstance.put(url, data, { headers })
  },

  delete(url) {
    return axiosInstance.delete(url)
  },
}
